import React, {useState, useEffect} from 'react';
import MultipleSlider from './MultipleSlider_Users';

import BlahCoin from "Assets/blah_coin.PNG"
import { ImagePlaceholder } from 'Components/ImagePlaceholder';


// const _fakeData = [
//     {
//         avatar_url : "/extra/images/profile/human/Asset 1.png",
//         username : "FLying Ninja",
//         blahcoin_today_earning : "40",
//     },

// ]


export const TopSnack = ({ snackrs = [], fakeDataList = [] ,onViewAll }) => {

    

    return (<div className="px-[20px] bg-violet-100 shadow rounded-lg mt-[20px] pb-[20px]">
        <div className="flex justify-between items-center ">

            <div className="py-[20px] text-zinc-900 font-bold text-[14px] text-center">

                Top snacker of the day
            </div>
            <div onClick={() => onViewAll()} className="bg-violet-500 shadow text-white p-[5px] px-[10px] rounded-xl text-[14px]"> View all</div>
        </div>
     
        <div className="">
            <MultipleSlider items={[...snackrs, ...fakeDataList]} />

        </div>
    </div>);
};


export const TopSnackList = ({ snackrs = [] }) => {

    return(<div className='h-[80vh] overflow-y-scroll ' >
            {
                [...snackrs]?.map(item => {
                    return(<div key={item.id} className='my-[5px] bg-white _bg-gradient-to-br from-violet-100 to-violet-300 shadow rounded-xl p-[10px]'>
                        <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <div className=" mr-[15px]">
                                        <div className="w-[50px] h-[50px] border-slate-200 border-[5px] bg-slate-300 rounded-full overflow-hidden">
                                            <ImagePlaceholder src={item?.avatar_url} alt="" />
                                        </div>
                                    </div>
                                <div className="text-slate-900">
                                    <div className="w-full text-[14px] truncate  max-w-[100px] pb-[5px] ">
                                        {item?.username}
                                    </div>

                                </div>
                                </div>
                                    <div className="text-[14px] truncate  max-w-[100px] flex items-center mr-[10px]">
                                        <img alt='coin' src={BlahCoin} className='w-[15px] h-[15px] mr-[5px]' />    
                                        <div className="text-slate-900 font-bold">
                                        {item?.blahcoin_today_earning}

                                        </div>
                                    </div>
                            </div>
                    </div>)
                })
            }
    </div>)
}
