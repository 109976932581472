import React, { Fragment, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "Auth/AuthContext";

import { API } from "constant";
import { setToken } from "helpers";
import { ButtonPrimary } from "Components/ButtonPrimary";
import axios from "axios";



const ResetContainer = () => {

    return (<div>
        <div className="h-screen _bg-zinc-800 bg-[#a07af6]">
            <ResetPassword />
        </div>
    </div>)
}


export const ResetPassword = ({ callback = () => { }, loginRedirect }) => {

    const navigate = useNavigate();

    // const { setUser } = useAuthContext();

    const [searchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState("");

    const [inputVals, setInputVals] = useState({
        email: "",
        password : "",
        passwordConfirmation : ""
       

    })

    const handleInputValues = (e) => {
        setError("")
        setInputVals(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }



    const onResetMail = async (values) => {

        console.log(searchParams.get("code"))
      
        setIsLoading(true);
        try {
          
          
            const data = await axios.post(`${API}/auth/reset-password`, {
                password : values.password,
                passwordConfirmation : values.passwordConfirmation,
                 code : searchParams.get("code")
            })

            // const data = await response.json();

            // console.log(data)
            if (data?.error) {
                throw data?.error;
            } else {
                 console.log(data)
                setSuccess(true)

            }
        } catch (ex) {
            // console.log(ex.response);
            setError(ex.response?.data?.error?.message ?? ex?.message ??  "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };


    return (


        <div className="flex flex-col h-full w-full  justify-between items-center">


            <div className=" w-full p-[20px] text-slate-900">
                
                <div className="font-bold text-[26px] text-white">
                 Reset password
                </div>
                <div className="text-slate-500 text-[14px] text-white">
                   Enter your new password .
                </div>

                {success ? 
                    <div
                       
                        className="text-center p-[14px] bg-white shadow text-[12px] rounded-xl mt-[20px] w-full">

                      Password reset successfull.

                      <div className="pt-[20px]">
                        <ButtonPrimary 
                         onClick={() => navigate("/")}
                        className="text-center w-full text-white">
                            Login
                        </ButtonPrimary>
                      </div>

                    </div> 
                    : 

                    <div className="w-full  py-[20px] rounded-xl text-slate-900">
{/* 
                        <div className="mt-[10px]">
                            <input
                                type="text" name="email"
                                className='bg-white w-full rounded-[20px]  p-[10px] '
                                placeholder='Email'
                                // disabled 
                                value={inputVals?.email}
                                onChange={handleInputValues}
                            />
                        </div> */}

                        <div className="mt-[10px]">
                            <input
                                type="password" name="password"
                                className='bg-white w-full rounded-[20px]  p-[10px] '
                                placeholder='Password'
                                value={inputVals?.password}

                                onChange={handleInputValues}

                            // disabled 
                            />
                        </div>
                        <div className="mt-[10px]">
                            <input
                                type="password" name="passwordConfirmation"
                                className='bg-white w-full rounded-[20px]  p-[10px] '
                                placeholder='Confirm Password'
                                value={inputVals?.passwopasswordConfirmationrd}

                                onChange={handleInputValues}

                            // disabled 
                            />
                        </div>
                    
                        <div className="text-center my-[20px] text-red-500 text-[12px]">
                            {error}
                        </div>
                        <div className="_mt-[20px]">

                            <ButtonPrimary
                                disabled={isLoading}
                                onClick={() => onResetMail(inputVals)} className=" w-full py-[10px] text-center text-white font-bold text-[12px]">
                                {isLoading ? "..." : "Reset Passsword"}
                            </ButtonPrimary>
                        </div>
                    </div>
                }

                {/* <div className="my-[30px] text-center text-[14px] text-slate-900">
                    <div className="font-bold">
                         <span  onClick={() => loginRedirect()} className="text-violet-500 cursor-pointer"> Go back</span>
                    </div>
                </div> */}
            </div>

        </div>
        // </div>

    );
}

export default ResetContainer;