import React from "react";

import { useNavigate, matchPath, useLocation } from "react-router-dom";


import Discover_active from "./UI_Images/bottomNav/discover_active.png"
import Discover_inactive from "./UI_Images/bottomNav/discover_inactive.png"

import {motion} from 'framer-motion'



function DiscoverButton() {

    const navigate = useNavigate()
    const { pathname } = useLocation();

    const isDiscover = matchPath("/discover", pathname);


    return (
        <div
            onClick={() => navigate("/discover")}
            className=" ">
            <div className="h-[40px] relative w-[60px]">
                {/* {isDiscover ?
                    <img src={Discover_active} className="h-[25px]" />
                    :
                    <img src={Discover_inactive} className="h-[25px]" />
                } */}

                {isDiscover ? 
                
                <motion.div  
                layoutId="nav_underline"    
                className="absolute left-0 top-[-10px] w-full h-[47px] bg-violet-400 rounded-b-xl shadow z-[100]"></motion.div> : null}
                <img src={Discover_inactive} className="h-[25px] relative z-[110] mx-auto" />
            </div>
        </div>
    )
}

export default DiscoverButton;