import { useState, useContext, useEffect } from "react";
import axios from "axios";

import { Store as snackStore } from 'StoreContext/snackStore'
import { API } from "constant";

import Carousal from "./Carousal";
import Search from "./Search";
import MultipleSlider from "./MultipleSlider";
import { motion } from "framer-motion";

import { sendAnalytics } from 'firebaseConfig';



function Discover() {

    useEffect(() => {
        sendAnalytics(`screen_view`, {
          firebase_screen: "Discover page",
          firebase_screen_class: "Discover page"
        })
      }, [])

    let _store_snack = useContext(snackStore)

    // const [Carousalimages, setCarousalImages] = useState([])

    const getTopSnack = (array = [], count) => {

        let val = _store_snack?.state?.allGames?.sort((a, b) => b.snack_count - a.snack_count) || [];

        if (val.length > 4) {
            val = val.slice(0, 4);
        }

        return val

    }

    const fetchAllSnacks = async () => {
        try {

            const res = await axios.get(`${API}/snackr/allSnacks`, {})
            let allGames = res?.data?.data?.games || []
            let allComics = res?.data?.data?.comics || []
            let allReels = res?.data?.data?.reels || []

            allGames = allGames.map(item => ({ ...item, type: "game" }))
            allComics = allComics.map(item => ({ ...item, type: "comic" }))
            allReels = allReels.map(item => ({ ...item, type: "reel" }))


            // console.log(res)

            _store_snack.dispatch({
                type: 'updateallGames',
                payload: { allGames: allGames }
            })
            _store_snack.dispatch({
                type: 'updateallComics',
                payload: { allComics: allComics }
            })
            _store_snack.dispatch({
                type: 'updateallReels',
                payload: { allReels: allReels }
            })

            // setvideos((oldVideos) => [...oldVideos, ...snacks]);


        } catch (ex) {

        }
    }

    useEffect(() => {

        fetchAllSnacks()

    }, [])


    // console.log(_store_snack?.state?.allReels)

    
    return (
    <div className="fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[401] h-full">
    <motion.div 
        initial={{ opacity: 0, _translateY: "100vh" }}
        animate={{ opacity: 1, _translateY: "0vh" }}
        exit={{ opacity: 0, _translateY: "100vh" }}
        transition={{ type: "Tween", stiffness: 50 }}
        className='fixed  md:max-w-[400px] md:left-auto  top-0 left-0 right-auto z-[550] bg-white _bg-zinc-900 h-full w-full  '>
        {/* Search */}
        {/* {console.log(getTopSnack())} */}
        <Search />
        <div className="overflow-y-scroll h-[90vh]">
        <Carousal images={getTopSnack()} />
        {/* slider */}
        {/* {console.log(_store_snack.state)} */}

            <div className="mt-[20px]"></div>
            <MultipleSlider items={_store_snack.state?.allGames} heading="Games" />
            <MultipleSlider items={_store_snack.state?.allComics} heading="Comics" />
            <MultipleSlider items={_store_snack?.state?.allReels} heading="Shots" />
            <div className="h-[100px]"></div>
        </div>



        {/* category */}

    </motion.div>
    </div>
    );
}

export default Discover;

