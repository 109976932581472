import { ButtonPrimary } from 'Components/ButtonPrimary';
import axios from 'axios';
import { API, BASE_URL } from 'constant';
import { AnimatePresence, motion } from 'framer-motion';
import { headers } from 'helpers';
import React, { useState } from 'react';

export const RedeemPage = ({ item = {} , onClose}) => {

    const [quantity, setQuantity] = useState(1)

    const addQuantity = () => {
        if (quantity >= item.max_per_person) {
            return
        }
        setQuantity(prev => prev + 1)
    }

    const reduceQuantity = () => {
        if (quantity == 1) {
            return
        }
        setQuantity(prev => prev - 1)
    }


    const [screen, setScreen] = useState(1)
    const [message, setMessage] = useState("")

    const [inputValues, setInputValues] = useState({
        name : "",
        email : "",
        address : "",
        phone : ""
    })

    const allow_proceed = inputValues?.name && inputValues?.email && inputValues?.address && inputValues.phone

    const handleInputValues = (e) => {
        setInputValues(prev => ({
            ...prev,
            [e.target.name] : e.target.value
        }))
    }

    const redeemItem = async() => {
        if(!allow_proceed){
            return
        }
        try {

            const res = await axios.post(`${API}/snackr/store/redeem`,{
                store_item_id : item.id,
                quantity : quantity,
                ...inputValues
               
            } ,{
                headers: headers()
            })

            
            if(res?.data?.message){
                console.log(res?.data?.message)
                setScreen(3)
                setMessage(res?.data?.message)

            }

           


        } catch (ex) {
            // console.log(ex.response?.data?.error?.message)
            setMessage(ex.response?.data?.error?.message || "Something went wrong")
            setScreen(4)
        }

    }



    return (<>
        <AnimatePresence>

            {
                screen === 1 && (
                    <motion.div
                        key={"checkout_screen_1"}
                        initial={{ opacity: 0, }}
                        animate={{ opacity: 1, }}
                        exit={{ opacity: 0, }}
                        // transition={{ type: "Tween", stiffness: 50 }}

                    >
                        <div className="">
                            <div className="">

                                <div className="bg-zinc-700 rounded-lg _p-[20px]">

                                    <div className="flex  p-[10px]">
                                        <div className=" max-w-[80px] rounded-lg overflow-hidden mr-[20px] ">
                                            <img src={`${BASE_URL}${item?.thumbnail?.url}`} alt="" className='w-full' />
                                        </div>
                                        <div className="w-full">
                                            <div className="text-[18px] ">
                                                {item?.title}
                                            </div>
                                            <div className="py-[10px] text-[12px] font-normal">
                                                {item?.description}
                                            </div>
                                            <div className="py-[10px] text-[12px] text-slate-400">
                                                Quantity :
                                            </div>
                                            <div className="_py-[10px] text-[16px] w-full">
                                                <div className="flex justify-between w-full items-center">
                                                    <div className="">
                                                        $B {item?.amount}
                                                    </div>
                                                    <div className="">
                                                        <QuantityButton addQuantity={addQuantity} reduceQuantity={reduceQuantity} quantity={quantity} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="bg-zinc-700 rounded-lg _p-[20px] mt-[20px] p-[10px] py-[20px]">

                                    <div className="flex  justify-between">
                                        <div className="text-slate-400">Total</div>
                                        <div className=""> $B {item?.amount * quantity} </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="fixed bottom-0 left-0  drop-shadow-xl border-t border-zinc-600  z-[6001] h-[150px] w-full ">
                            <div className="p-[10px]">

                                <div onClick={() => setScreen(2)} className="w-full p-[20px]  _bg-zinc-400 bg-[#a07af6] rounded-xl text-center">
                                    Proceed
                                </div>
                            </div>
                        </div>
                    </motion.div>

                )
            }
            {
                screen == 2 && (
                    <motion.div
                        key={"checkout_screen_2"}
                        initial={{ opacity: 0, }}
                        animate={{ opacity: 1, }}
                        exit={{ opacity: 0, }}
                        // transition={{ type: "Tween", stiffness: 50 }}
                    >
                        <div className="_py-[10px]">
                            <div className="p-[10px] bg-zinc-700 rounded-xl mt-[20px] py-[20px]">

                                <div className="">
                                   Shipping address
                                </div>
                                <div className="mt-[10px]">
                                    <input type="text" onChange={(e)=> handleInputValues(e)}  name="name"  className='bg-zinc-900 w-full rounded-lg  p-[10px]' placeholder='Name' />
                                </div>
                                <div className="mt-[10px]">
                                    <input type="text" onChange={(e)=> handleInputValues(e)}  name="email" className='bg-zinc-900 w-full rounded-lg  p-[10px]' placeholder='Email' />
                                </div>
                                <div className="mt-[10px]">
                                    <input type="text" onChange={(e)=> handleInputValues(e)}  name="phone" className='bg-zinc-900 w-full rounded-lg  p-[10px]' placeholder='Phone' />
                                </div>
                                <div className="mt-[10px]">
                                    <input type="textarea"  onChange={(e)=> handleInputValues(e)}  name="address" className='bg-zinc-900 w-full rounded-lg  p-[10px]' placeholder='Address' />
                                </div>
                                {/* <div className="mt-[10px]">
                                    <ButtonPrimary>
                                        Update
                                    </ButtonPrimary>
                                </div> */}
                            </div>
                        </div>
                        <div className="p-[10px] bg-zinc-700 rounded-xl mt-[20px] py-[20px]">
                                <div className="flex justify-between items-center">

                                    <div className="text-slate-500">
                                    Payment mode
                                    </div>
                                    <div className="_text-slate-500">
                                        $BlahCoin
                                    </div>
                                </div>
                                <div className="flex justify-between items-center mt-[20px]">

                                    <div className="text-slate-500">
                                    Amount
                                    </div>
                                    <div className="_text-slate-500 text-[20px]">
                                        $B {item?.amount * quantity}
                                    </div>
                                </div>
                        </div>
                        <div className="fixed bottom-0 left-0  drop-shadow-xl border-t border-zinc-600  z-[6001] h-[150px] w-full ">
                            <div className="p-[10px]">

                                <div onClick={() => redeemItem()} className={`w-full p-[20px] ${allow_proceed ? "bg-[#a07af6]" : "bg-zinc-600"} rounded-xl text-center`}>
                                    Check out
                                </div>
                            </div>
                        </div>

                    </motion.div>)
            }
             {
                screen == 3 && ( <motion.div
                    key={"checkout_screen_3"}
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    exit={{ opacity: 0, }}
                    // transition={{ type: "Tween", stiffness: 50 }}
                >
                    <div className="w-full">
                        <div className="mt-[40px]">
                            <div className="mx-auto w-fit">
                            <svg fill='white' xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                            </div>
                        </div>
                    </div>
                    <div className="text-[20px] text-center mt-[20px]">
                        Thank you
                    </div>
                    <div className="text-center _mt-[15px] p-[10px] max-w-[300px] mx-auto">
                       {message} 
                    </div>

                    <div className="fixed bottom-0 left-0  drop-shadow-xl border-t border-zinc-600  z-[6001] h-[150px] w-full ">
                            <div className="p-[10px]">

                                <div onClick={() => onClose()} className="w-full p-[20px]  bg-zinc-400 _bg-[#a07af6] rounded-xl text-center">
                                    OK
                                </div>
                            </div>
                        </div>
                </motion.div>)
            }
             {
                screen == 4 && ( <motion.div
                    key={"checkout_screen_4"}
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    exit={{ opacity: 0, }}
                    // transition={{ type: "Tween", stiffness: 50 }}
                >
                    <div className="w-full">
                        <div className="mt-[40px]">
                            <div className="mx-auto w-fit">
                            <svg  fill='white'  width="80" height="80"  clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" fill-rule="nonzero"/></svg>
                            </div>
                        </div>
                    </div>
                    <div className="text-[20px] text-center mt-[20px]">
                        Oops!
                    </div>
                    <div className="text-center _mt-[15px] p-[10px] max-w-[300px] mx-auto">
                       {message} 
                    </div>

                    <div className="fixed bottom-0 left-0  drop-shadow-xl border-t border-zinc-600  z-[6001] h-[150px] w-full ">
                            <div className="p-[10px]">

                                <div onClick={() => onClose()} className="w-full p-[20px]  bg-zinc-400 _bg-[#a07af6] rounded-xl text-center">
                                    OK
                                </div>
                            </div>
                        </div>
                </motion.div>)
            }
        </AnimatePresence>

    </>

    );
};


const QuantityButton = ({ addQuantity, reduceQuantity, quantity }) => {

    return (
        <div className="flex items-center">
            <div
                onClick={() => reduceQuantity()}
                className="w-[20px]">
                <svg fill='white' clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm4.253 9.25h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z" fill-rule="nonzero" /></svg>
            </div>
            <div className="px-[5px] text-[12px]">
                {quantity}
            </div>
            <div
                onClick={() => addQuantity()}
                className="w-[20px]">
                <svg fill='white' clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm-.747 9.25h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero" /></svg>
            </div>
        </div>
    )
}