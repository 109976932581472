import { useState } from "react";

import Bg from "Assets/profileBG.JPG"
import { ButtonPrimary } from "Components/ButtonPrimary";
import { API } from "constant";
import axios from "axios";
import { ButtonSecondary } from "Components/ButtonSecondary";
import { Link } from "react-router-dom";

import { removeToken } from 'helpers';
import { useNavigate } from 'react-router-dom';

function DeleteUser() {

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const [inputVals, setInputVals] = useState({
        password: "",
        newPassword: "",
        confirmPassword: "",

    })

    const handleInputValues = (e) => {
        setError("")
        setInputVals(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const ReloadWindow = () => {
        window.location.href = "/";
    }


    const onDeleteUser = async (values) => {



        setIsLoading(true);
        try {

            const data = await axios.post(`${API}/snackr/user/deleteuser`, {
                password: inputVals.password,
                email: inputVals.email
            })

            removeToken()
            setSuccess(true)
            setTimeout(() => {
                ReloadWindow()
            }, 1000)

            // console.log(data)
            


        } catch (ex) {
            // console.log()
            console.log(ex.response);
            setError(ex.response?.data?.error?.message ?? ex?.message ?? "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    return (<div className='fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[90] h-full' >
        <div
            style={{ background: `url(${Bg}) white `, backgroundSize: "cover" }}
            className="mt-[20px]  px-[12px]  h-full pt-[100px]">

          {!success ?  
            
            <div className="shadow bg-violet-100 p-[20px] rounded-xl text-slate-900  ">
                <div className="text-[14px] font-bold py-[10px] text-center">
                   Delete SnackR account
                </div>
                <div className="text-[14px]  pb-[20px] text-center">
                Are you sure you want to delete the SnackR account? All data associated with this user will be removed immediately and will not be retrievable.
                </div>
                <div className="pb-[20px]">

                    <input
                        onChange={handleInputValues}
                        name="email"
                        type="text" className='bg-white w-full rounded-full  p-[10px]' placeholder='Email' />

                </div>
                <div className="pb-[20px]">

                    <input
                        onChange={handleInputValues}
                        name="password"
                        type="password" className='bg-white  w-full rounded-full  p-[10px]' placeholder=' password' />
                </div>
                <div className="text-red-500 text-[12px] text-center py-[5px]">
                    {error || ""}
                </div>
                <div className="">
                    <ButtonPrimary
                        disabled={isLoading}
                        className="w-full py-[10px] text-center text-white cursor-pointer"
                        onClick={() => onDeleteUser()}>
                        {isLoading ? "..." : "Delete user"}
                    </ButtonPrimary>
                </div>
                <div className="pt-[20px]">
                    <Link to={"/profile"}>
                        <ButtonSecondary
                            disabled={isLoading}
                            className="w-full py-[10px] text-center text-white"
                        // onClick={() => onDeleteUser()}
                        >
                            NO
                        </ButtonSecondary>
                    </Link>
                </div>
            </div>

            :

            <div className="shadow bg-violet-100 p-[20px] rounded-xl text-slate-900  ">
                <div className="text-[14px] font-bold py-[10px] text-center">
                   Account deleted :( 
                </div>
            </div>
            }

        </div>
    </div>);
}

export default DeleteUser;