import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "Auth/AuthContext";

import { API } from "constant";
import { setToken } from "helpers";
import { ButtonPrimary } from "Components/ButtonPrimary";
import axios from "axios";



const ForgetContainer = () => {

    return (<div>
        <div className="h-screen _bg-zinc-800 bg-[#a07af6]">
            <ForgetPassword />
        </div>
    </div>)
}


export const ForgetPassword = ({ callback = () => { }, loginRedirect }) => {

    const navigate = useNavigate();

    const { setUser } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState("");

    const [inputVals, setInputVals] = useState({
        email: "",
       

    })

    const handleInputValues = (e) => {
        setError("")
        setInputVals(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }



    const onResetMail = async (values) => {
      
        setIsLoading(true);
        try {
            const value = {
               
                email: values.email,
              
            };
            // const response = await fetch(`http://localhost:1337/api/auth/local/forgot-password`, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: value,
            // });
            const data = await axios.post(`${API}/auth/forgot-password`, {
                email : values.email
            })

            // const data = await response.json();

            // console.log(data)
            if (data?.error) {
                throw data?.error;
            } else {
                 console.log(data)
                setSuccess(true)

            }
        } catch (ex) {
            console.log( ex.response?.data?.error?.message);
            setError(ex.response?.data?.error?.message ?? ex?.message ??  "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };


    return (


        <div className="flex flex-col h-full w-full  justify-between items-center">


            <div className=" w-full p-[20px] text-slate-900">
                
                <div className="font-bold text-[24px]">
                    Forget Password ?
                </div>
                <div className="text-slate-500 text-[14px]">
                   We can help reset your password.
                </div>

                {success ? 
                    <div className="_text-center p-[10px] bg-white shadow text-[14px] rounded-xl mt-[20px] w-full">

                        We've sent a recovery mail to {inputVals.email}. <br/> please open the reset link and enter  new password to recover your account

                    </div> 
                    : 

                    <div className="w-full  py-[20px] rounded-xl text-slate-900 text-[14px]">

                        <div className="mt-[10px]">
                            <input
                                type="text" name="email"
                                className='bg-white w-full rounded-[20px]  p-[10px] '
                                placeholder='Email'
                                // disabled 
                                value={inputVals?.email}
                                onChange={handleInputValues}
                            />
                        </div>
                    
                        <div className="text-center my-[20px] text-red-500 text-[12px]">
                            {error}
                        </div>
                        <div className="_mt-[20px]">

                            <ButtonPrimary
                                disabled={isLoading}
                                onClick={() => onResetMail(inputVals)} className=" w-full py-[10px] text-center text-white font-bold text-[14px]">
                                {isLoading ? "..." : "Reset Passsword"}
                            </ButtonPrimary>
                        </div>
                    </div>
                }

                <div className="my-[30px] text-center text-[14px] text-slate-900">
                    <div className="font-bold">
                         <span  onClick={() => loginRedirect()} className="text-violet-500 cursor-pointer"> Go back</span>
                    </div>
                </div>
            </div>

        </div>
        // </div>

    );
}

export default ForgetContainer;