import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, BEARER } from "./constant";
import { useEffect } from "react";

export const getToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
    if (token) {
        localStorage.setItem(AUTH_TOKEN, token);
    }
};

export const removeToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
};

export const headers = () => {

    const token = getToken()

    return { Authorization: `${BEARER} ${token}` }
}



export const Protector = ({ Component }) => {
    const navigate = useNavigate()
    const jwt = getToken()

    useEffect(() => {
        if (!jwt) {
            // alert("no jwt")
            // navigate("/login")
        } else {
            // alert("fetching user")
        }

    }, [jwt, navigate])

    return <Component />

}


export function randomSort(arr = [], preserveCount = 0) {
    if (preserveCount >= arr.length) {
        return arr; // No need to shuffle if preserveCount is equal to or greater than array length
    }

    const preservedItems = arr.slice(0, preserveCount);
    const toShuffle = arr.slice(preserveCount);

    // Randomize both arrays separately
    for (let i = preservedItems.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [preservedItems[i], preservedItems[j]] = [preservedItems[j], preservedItems[i]];
    }
    for (let i = toShuffle.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [toShuffle[i], toShuffle[j]] = [toShuffle[j], toShuffle[i]];
    }

    return [...preservedItems, ...toShuffle];
}