import { ButtonPrimary } from "Components/ButtonPrimary";
import { ImagePlaceholder } from "Components/ImagePlaceholder";
import axios from "axios";
import { API } from "constant";
import { headers } from "helpers";
import { useEffect, useState } from "react";

function AvatarEditor({
    callback = () => {},
    activeAvatar = ""
    }) {

    const _avatar_list = [
        "/extra/images/profile/human/Asset 1.png",
        "/extra/images/profile/human/Asset 2.png",
        "/extra/images/profile/human/Asset 4.png",
        "/extra/images/profile/human/Asset 5.png",
        "/extra/images/profile/human/Asset 6.png",
        "/extra/images/profile/human/Asset 7.png",

        "/extra/images/profile/Vegies/Asset 4.png",
        "/extra/images/profile/Vegies/Asset 5.png",
        "/extra/images/profile/Vegies/Asset 6.png",
        "/extra/images/profile/Vegies/Asset 7.png",
        "/extra/images/profile/Vegies/Asset 8.png",
        "/extra/images/profile/Vegies/Asset 9.png",
       
    ]

    const [activeImage, setActiveImage] = useState( "/extra/images/profile/Asset 1.png")
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setActiveImage(activeAvatar)
    }, [])





    const updateAvataURL = async() => {
        setIsLoading(true)
        try {
            const res = await axios.post(`${API}/snackr/user/updateAvatar`,
              {
                avatarURL : activeImage

              }, {
              headers: headers()
            })
            
            if(res?.data?.user){
                // console.log(res)
                callback()
            }

            


            
        } catch (ex) {

            setError(ex.response?.data?.error?.message || "Something went wrong")
            console.log(ex)
          }
        setIsLoading(false)

    }

    return (<div className="h-[80vh] overflow-y-scroll text-black  _flex flex-col justify-between">
        <div className="">

        <div className="">
            <div className="text-[14px] font-bold">
                Change your avatar
            </div>
        </div>
      <div className=" grid grid-cols-4 mt-[20px] _bg-violet-100 p-[5px]  rounded-[15px]">
        {
            _avatar_list.map(item => {
                return(
                <div className="w-full">

                <div 
                    id={item} 
                    onClick={() => setActiveImage(item)} 
                    className={`w-fit h-fit _max-w-[65px] bg-violet-200 rounded-full border-4 overflow-hidden  mb-[10px]  ${item === activeImage ? "  border-violet-500" : " border-slate-100"}`} >
                     <div className="w-[60px]  h-[60px] rounded-full  ">

                        <ImagePlaceholder src={item} alt="" className="w-full h-full mx-auto"  />
                     </div>
                </div>
                </div>
                )
            })
        }
      </div>
      </div>

      <div className="pb-[50px] border-t-[5px] border-slate-200 pt-[20px] mt-[20px]">
        <div className="">

        <ButtonPrimary disabled={isLoading} onClick={() => updateAvataURL() } className="w-full text-[14px] text-center text-white py-[10px]">
          {isLoading ? "..." : "Equip avatar"}
        </ButtonPrimary>
        </div>
      </div>
    </div> );
}

export default AvatarEditor;