import { useEffect, useState } from 'react';

export const useIsPWA = () => {
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(display-mode: standalone)');

    const handleChange = (event) => {
      setIsPWA(event.matches);
    };

    // Initial check
    handleChange(mediaQueryList);

    // Listen for changes
    mediaQueryList.addListener(handleChange);

    // Clean up
    return () => {
      mediaQueryList.removeListener(handleChange);
    };
  }, []);

  return isPWA;
};

