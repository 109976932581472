import IconBookMark from "../Buttons/IconBookMark";
import IconHeart from "../Buttons/IconHeart";
import IconProfile from "../Buttons/IconProfile";
import IconShare from "../Buttons/IconShare";

function RighSideNav({

    onClick_publisherBtn,
    
    is_liked, 
    like_count = 0,
    onClick_likeBtn,

    is_bookmarked,
    onClick_bookMrkBtn,

    id,
    gamename,
    snackType,
    gameUrl,

    isPWA,
    publisherDetails,
    

}) {
    return (<div className={` absolute ${isPWA ? " bottom-[100px]" : "bottom-[180px]  sm:bottom-[100px]"}  right-0 mr-[5px] z-[25] `}>
        <div className="flex flex-col items-center w-[50px] h-[300px] justify-around _bg-black/40 rounded-xl _backdrop-blur-sm">
            {/* <div></div> */}
            <IconProfile onClick={onClick_publisherBtn} image={publisherDetails?.avatar_publisher_url} />
            <IconHeart isLiked={is_liked} likes={like_count} onClick={onClick_likeBtn} />
            <IconBookMark isBookmarked={is_bookmarked} onClick={onClick_bookMrkBtn} />
            <IconShare id={id} gamename={gamename} snackType={snackType} game_url={gameUrl} />
         
        </div>

    </div>);
}

export default RighSideNav;