import { useNavigate } from "react-router-dom";

import {useContext, useEffect, useState} from 'react'

import { Store as walletStore } from 'StoreContext/wallatStore'
import { useAuthContext } from "Auth/AuthContext";


import BlahCoin from "Assets/blah_coin.PNG"
import { motion } from "framer-motion";
import { ButtonPrimary } from "Components/ButtonPrimary";
import { ButtonSecondary } from "Components/ButtonSecondary";




export const WalletButton = ({ holdClick = false }) => {

    const navigate = useNavigate();
    const { user } = useAuthContext();


    let _store_wallet = useContext(walletStore)

    const variants = {
        open: { width : "100%", height : "130px"},
        closed: { height : "28px", width : ""  },
    }

    const [isOpen, setIsOpen] = useState(false)


    const WalletContent = () => {

        return(<div className="flex items-center px-[15px] my-[2px] ">
             <div className=" ">
                <img alt="" src={BlahCoin} className="h-[15px] w-[15px]" />
                {/* <svg className="mx-auto" fill="black" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M4 6v16h20v-16h-20zm10 13c-2.761 0-5-2.239-5-5s2.239-5 5-5 5 2.239 5 5-2.239 5-5 5zm.292-2.114v.614h-.584v-.582c-.603-.01-1.229-.154-1.75-.424l.266-.958c.558.216 1.3.445 1.881.315.671-.152.809-.842.068-1.175-.543-.251-2.205-.469-2.205-1.891 0-.795.605-1.508 1.74-1.663v-.622h.584v.593c.422.011.896.085 1.426.246l-.212.96c-.448-.156-.943-.3-1.425-.27-.868.051-.946.803-.339 1.118.998.469 2.301.818 2.301 2.068.001 1.002-.784 1.537-1.751 1.671zm6.708-12.886h-19v15h-2v-17h21v2z" /></svg> */}
            </div>
            <div className="text-black ml-[10px] font-bold">
                {(_store_wallet?.state?.BlahCoin)}
              
            </div>
        </div>)
    }

    

    const checkIsLoggedAndPrompt = () => {

        if(!user){
            setIsOpen(true)
        } 

    }

    const GotoWallet = () => {
        if(holdClick){
            return
        }
        
        if(user){
            navigate("/profile?popup=history")
        } else {
            setIsOpen(true)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            checkIsLoggedAndPrompt();
        }, 5000); 
    
        return () => clearTimeout(timer);
      }, []); 


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsOpen(false)
        }, 15000); 
    
        return () => clearTimeout(timer);
      }, []); 
    


    

    // console.log(_wallat_store)
    return (
        <motion.div
            animate={(isOpen && !holdClick) ? "open" : "closed"}
            variants={variants}
            transition={{
                type: "spring",
                stiffness: 400,
                damping: 40
            }}
            onClick={() => GotoWallet()}
            className="  relative  bg-white rounded-[20px] _h-[30px] min-w-[65px]">
                {((isOpen && !holdClick) && !user) ? <>
                    <motion.div 
                          initial={{ opacity: 0, }}
                        animate={{ opacity: 1, }}
                        transition={{
                            delay : 0.3
                        }}
                        className="py-[20px] px-[20px]" >
                        <div className="text-violet-500 font-bold text-[16px]"> Welcome. </div>
                        <div className="text-slate-500  text-[14px] pt-[5px]"> Login for a more rewarding experience! </div>
                        <div className="pt-[10px] flex text-[14px]">
                            <div className="">  
                                <ButtonPrimary 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsOpen(false) 
                                        navigate("/profile?popup=login")
                                    }}
                                    className="text-center min-w-[80px] text-[14px]"> Login </ButtonPrimary> 
                            </div>
                            <div className="ml-[10px]">  
                            <ButtonSecondary  
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsOpen(false)
                                }} className="text-center min-w-[80px] text-[14px]"> Not now </ButtonSecondary> </div>
                        </div>
                    </motion.div>
                </> : 
                <WalletContent /> }
           
           
        </motion.div>
    );
};
