import React, { useEffect, useState } from "react";
import axios from "axios";

import MultipleSlider from "Pages/Discover/MultipleSlider";
import { ProfileDetails } from "Pages/Profile";
import { API } from "constant";


function PublisherDetails({publisher}) {

    const [userData, setUserData] = useState({})
    let games = userData?.published_games || []
    let comics = userData?.published_comics || []
    let reels = userData?.published_reels || []
   

    const fetchDetails = async() => {
        try{

            const res = await axios.get(`${API}/snackr/publisher/${publisher}`, {})
            // console.log(res)
            setUserData(res?.data?.publisher || {})
            

        } catch(ex) {

        }
    }

    useEffect(()=> {
        fetchDetails()
    }, [])

    // console.log( userData)

    return ( <div className="overflow-y-scroll h-[600px]">
        <ProfileDetails user={{
                username : userData?.username, 
                profile_slug : "publisher", 
                avatar_url :  userData?.avatar_publisher_url || userData?.avatar_url,
                publisher_description : userData?.publisher_description
                }} />
        <div className="mt-[20px] bg-slate-100 pt-[5px]">
            {
                games?.length > 0 &&  <MultipleSlider items={games?.length < 4 ? [...games, ...games, ...games, ...games] : games} heading="Games" />
            }
            {
                comics?.length > 0 &&  <MultipleSlider items={comics?.length < 4 ? [...comics, ...comics, ...comics, ...comics] : comics} heading="Comics" />
            }
            {
                reels?.length > 0 &&  <MultipleSlider items={reels?.length < 4 ? [...reels, ...reels, ...reels, ...reels] : reels} heading="Shots" />
            }
            
        </div>
        <div className="mb-[200px]"></div>
    </div> );
}

export default PublisherDetails;