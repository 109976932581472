import React from "react";
import {motion} from 'framer-motion'

import { useNavigate, matchPath, useLocation } from "react-router-dom";



import profile_active from "./UI_Images/bottomNav/profile_active.png"
import profile_inactive from "./UI_Images/bottomNav/profile_inactive.png"




function DiscoverButton() {

    const navigate = useNavigate()
    const { pathname } = useLocation();

    const isProfile = matchPath("/profile/*", pathname);


    return (
        <div
            onClick={() => navigate("/profile")}
            className=" ">
            {/* <div className="h-[40px] ">
                {isProfile ?
                    <img src={profile_active} className="h-[25px]" />
                    :
                    <img src={profile_inactive} className="h-[25px]" />
                }
            </div> */}
            <div className="h-[40px] relative w-[60px] ">
                {isProfile ? 
                
                <motion.div  
                    layoutId="nav_underline"    
                    className="absolute left-0 top-[-10px] w-full h-[47px] bg-violet-400 rounded-b-xl shadow  z-[100]"></motion.div> : null}

                    <img src={profile_inactive} className="h-[25px] relative z-[110] mx-auto" />
              
            </div>
        </div>
    )
}

export default DiscoverButton;