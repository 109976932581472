import React, { useEffect, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { API } from 'constant';
// import Container from '../../Component/Container';

import { Store as wallatStore } from 'StoreContext/wallatStore'

import PopOverlay from 'Components/PopupOverlay';
import { Noitems } from './Noitems';
import { StoreItems } from './StoreItems';
import { RedeemPage } from './RedeemPage';
import { useAuthContext } from 'Auth/AuthContext';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from 'Components/ButtonPrimary';

import Store_avatar from "Assets/BaBlah_instore.png"
import Bg from "Assets/profileBG.JPG"

//=============================================
//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================



function Store() {

    let _store_wallet = useContext(wallatStore)

    const { user } = useAuthContext();

    useEffect(() => {
        sendAnalytics(`screen_view`, {
            firebase_screen: "Store page",
            firebase_screen_class: "Store page"
        })
    }, [])



    const fetchStoreData = async () => {
        try {

            const res = await axios.get(`${API}/snackr/store/all`, {
                // headers: headers()
            })

            const storeData = res?.data?.storeData || []

            console.log(storeData)

            _store_wallet.dispatch({
                type: 'initStoreData',
                payload: { storeData: storeData }
            })
            // setvideos((oldVideos) => [...oldVideos, ...snacks]);


        } catch (ex) {

        }
    }

    useEffect(() => {
        // fetchStoreData()
    }, [])

    const _storeData = _store_wallet.state?.store_storeData || []


    //===========================================
    //TOp users
    //===========================================


    const [isCart, setIsCart] = useState(false)
    const [cartItem, setCartItem] = useState({})

    const openCartPage = (item = {}) => {
        setIsCart(true)
        setCartItem(item)
    }

    const closeCartPage = () => {
        setIsCart(false)
    }

    //===========================================
    //===========================================



    return (<div className='fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[90] h-full '>
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}
            className="fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[100] h-full " >
            {/* <Container> */}
            {/* {console.log(_storeData)} */}

            <div 
                  style={{ background : `url(${Bg}) white ` , backgroundSize : "cover"}}
                className="bg-white h-full">
                {/* <Noitems /> */}
                <div className="flex flex-col justify-center items-center h-full ">
                    {/* <div className="">
                                <svg fill='grey' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M6 23.73l-3-2.122v-14.2l3 1.359v14.963zm2-14.855v15.125l13-1.954v-15.046l-13 1.875zm5.963-7.875c-2.097 0-3.958 2.005-3.962 4.266l-.001 1.683c0 .305.273.54.575.494.244-.037.425-.247.425-.494v-1.681c.003-1.71 1.416-3.268 2.963-3.268.537 0 1.016.195 1.384.564.422.423.654 1.035.653 1.727v1.747c0 .305.273.54.575.494.243-.037.423-.246.423-.492l.002-1.749c.002-1.904-1.32-3.291-3.037-3.291zm-6.39 5.995c.245-.037.427-.247.427-.495v-2.232c.002-1.71 1.416-3.268 2.963-3.268l.162.015c.366-.283.765-.513 1.188-.683-.405-.207-.858-.332-1.35-.332-2.096 0-3.958 2.005-3.962 4.266v2.235c0 .306.272.538.572.494z"/></svg>
                            </div> */}
                    <div className="bg-white rounded-xl shadow px-[20px]">

                        <div className="">
                            <img src={Store_avatar} className='w-[150px] mx-auto' alt="" />
                        </div>
                        <div className="text-center text-slate-800 mt-[10px] font-bold text-[20px]"> Exciting Stuff </div>
                        <div className="text-center text-slate-500 mt-[px] mb-[20px] text-[14px]"> Coming Soon </div>
                    </div>
                </div>

                {/* {!user ? 
                <PromptLogin /> :
                    <div className="h-full">

                        {_storeData?.length <= 0 ?
                            <Noitems />
                            :
                            <StoreItems
                                items={_storeData}
                                onCartOpen={openCartPage}
                            />
                        }
                       
                    </div>
                } */}


            </div>
            {/* </Container> */}


        </motion.div>

        <PopOverlay isVisible={isCart} onClose={() => closeCartPage()} >

            <RedeemPage item={cartItem} onClose={() => closeCartPage()} />
        </PopOverlay>
    </div>);
}

const PromptLogin = () => {

    return (
        <div className="h-full _flex _flex-col justify-center items-center p-[20px] mt-[100px]">

            <div className=' w-full  bg-zinc-800 text-black  rounded-lg p-[20px] text-white text-center ' >
                <div className="">

                    Login for more  options

                </div>
                <div className="">

                    <div className="w-fit mx-auto mt-[20px]">
                        <Link to="/login">
                            <ButtonPrimary > Login </ButtonPrimary>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Store;


