export default function IconPlane  (props)  {

    const {snackType, gamename, id, game_url=""} = props

    return (<div>
        <a href={`whatsapp://send?text=Checkout ${gamename} in SnackR. %0Ahttps%3A%2F%2Fsnackr.banzan.co%3Fgame_url%3D${game_url}%26%26snack_type%3D${snackType}%26%26snack_id%3D${id}%26%26snack_name%3D${gamename}%26%26`}
        data-action="share/whatsapp/share"
        target="_blank">
       
        <svg fill="white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 16v5l9-10.062-9-9.938v5c-14.069 1.711-19.348 15.107-10.606 16.981-3.804-2.936 3.251-7.441 10.606-6.981z"/></svg>
    </a>
       
    </div>);
};
