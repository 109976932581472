import React from 'react';

export const TransactionList = ({ items = [] }) => {

    return(<div className='h-[80vh] overflow-y-scroll' >
            {
                [...items]?.map(item => {
                    return(<div key={item.id} className='my-[10px] bg-zinc-700 rounded-xl p-[10px]'>
                        <div className="flex items-center justify-between">
                                {/* <div className="">
                                    <div className=" mr-[15px]">
                                        <div className="w-[50px] h-[50px] bg-slate-300 rounded-lg overflow-hidden">
                                            <img src={item?.avatar_url} alt="" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="">
                                    <div className="w-full text-[12px] truncate  max-w-[100px] pb-[5px]  ">
                                        {item?.comment}
                                    </div>
                                    <div className="text-[12px]  mx-auto ">
                                      Credit deducted : $B {item?.bought_items?.[0]?.amount}
                                    </div>
                                    <div className="text-[12px]  mx-auto text-slate-500">
                                     Date :  {(`${item?.date}`).substring(0,10)}
                                    </div>

                                </div>
                                <div className="">
                                    <div className="w-fit text-[12px] bg-yellow-500/30 p-[5px] rounded-lg">
                                        {item.delivery_status}
                                    </div>
                                </div>
                            </div>
                    </div>)
                })
            }
    </div>)
}
