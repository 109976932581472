import React from "react";

import { useNavigate, matchPath, useLocation } from "react-router-dom";


import shop_inactive from "./UI_Images/bottomNav/shop_inactive.png"
import shop_active from "./UI_Images/bottomNav/shop_active.png"

import {motion} from 'framer-motion'


function StoreButton() {

    const navigate = useNavigate()
    const { pathname } = useLocation();

    const isStore = matchPath("/store/*", pathname);

    return (
        <div
            onClick={() => navigate("/store")}
            className=" ">
            {/* <div className="h-[40px] ">
                {isStore ?
                    <img src={shop_active} className="h-[25px]" />
                    :
                    <img src={shop_inactive} className="h-[25px]" />
                }
            </div> */}
             <div className="h-[40px] relative w-[60px] ">
                {isStore ? 
                
                <motion.div  
                    layoutId="nav_underline"    
                    className="absolute left-0 top-[-10px] w-full h-[47px] bg-violet-400 rounded-b-xl shadow z-[100]"></motion.div> : null}

                    <img src={shop_inactive} className="h-[25px] relative z-[110] mx-auto" />
              
            </div>
        </div>
    )
}

export default StoreButton;