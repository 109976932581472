import React, { useState, useEffect, useContext } from "react";
import Iframe from 'react-iframe'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SnackContainer from "./SnackContainer";

//=============================================
//=============================================
import axios from "axios";
import { API } from "constant";
import { headers } from "helpers";
import { Store as walletStore } from 'StoreContext/wallatStore'

//=============================================
//=============================================

//=============================================
//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================



function GamePlay(props) {

    const { url, snack_id, snack_type, snack_name, updateWallatScore = () => { }, updateHighScore = () => { } } = props
    
    
    let _store_wallet = useContext(walletStore)

    //==============================================
    //==============================================



    const [startTime, setStartTime] = useState(Date.now());
    
    
    useEffect(() => {
        sendAnalytics( `Snacked game`, {
            // game_name : snack_name,
            [snack_name] : _store_wallet?.state?.user?.username || "Guest"
        })
        
        sendAnalytics( `screen_view`, {
            firebase_screen: snack_name, 
            firebase_screen_class: "Snack Page"
        })

        return () => {
            const endTime = Date.now();
            const durationInSeconds = (endTime - startTime) / 1000;
      
            // Log custom event to track time spent on screen/page
            sendAnalytics('Snacked game timespent', {
             
              [snack_name]: durationInSeconds
            });
          };

    }, []);

    //==============================================
    //==============================================

    
        
        
        
        
        



    //==============================================
    const location = useNavigate()
    const history = useLocation()

    const closeCallback = () => {
        //    console.log(location)
        if (history.key !== "default") {

            location(-1)
        } else {
            location("/")
        }
        // console.log(location(-1))
    }

   


    //==================================================
    //==================================================
    // inter iframe communitcation


    useEffect(() => {
        window.addEventListener('message', handleExternalMessage);
        return () => {
            window.removeEventListener('message', handleExternalMessage);
        };
    }, []);

    const handleExternalMessage = (event) => {

        if (event.origin === url) {
            console.log('Message received from iframe:', event.data);
            if (event.data?.eventKey == "game_end") {

            }
            else if (event.data?.eventKey == "game_start") {
                // const _score = event?.data?.score || 0
                // updateWallatScore(_score)
                // handleStart()
            }
        }
    };

    //==================================================
    //==================================================
    //function to update user snack Score



    const updateWallet = async(point = 0) => {

        try{

            //call api to update strapi
            const res = await axios.post(`${API}/snackr/user/blahcoin`,{
                point : point,
                snack_type : snack_type,
                snack_id : snack_id
            }, { headers: headers() })

            // get coin number

            const BlahCoin = res?.data?.BlahCoin

            if(BlahCoin){

                _store_wallet.dispatch({
                    type: 'updateBlahCoin',
                    payload: { BlahCoin }
                })

            }
                
        } catch(ex){

        }
    }


    const updateInterval = 30000;  //update snack count every 30 seconds

    useEffect(() => {
        // Function to call saveTime() every 10 seconds
        const intervalId = setInterval(() => {
            updateWallet(1)
        }, updateInterval);


        // Clean up the intervals when the component is unmounted
        return () => {
            clearInterval(intervalId);
            // handleComponentExit();
        };
    }, []);

    


    return (
        <SnackContainer closeCallback={closeCallback} >
            <Iframe url={url}
                id=""
                className="w-full  h-[100vh] overflow-hidden  snap-scrollbar bg-[#a07af6] md:max-w-[400px] md:left-auto z-[200]"
                // styles={{maxWidth : "400px"}}
                display="block"
                position="relative" />
        </SnackContainer>
    );
}

export default GamePlay;