import React from 'react';
import MultipleSlider from './MultipleSlider_Snacks';
import { BASE_URL } from 'constant';
import BlahCoin from "Assets/blah_coin.PNG"
import { ImagePlaceholder } from 'Components/ImagePlaceholder';


export const YourSnacks = ({ user, onViewAll }) => {
    // console.log(user)

    const games_history = user?.games_history || [];
    const reels_history = user?.reels_history || [];
    const comics_history = user?.comics_history || [];

    let full_history = [...games_history, ...reels_history, ...comics_history] || [];

    full_history?.sort((a, b) => b.earned_points - a.earned_points);



    return (<div className="px-[20px]">
        <div className="flex justify-between items-center">

            <div className="py-[20px] text-white font-bold text-[18px] text-center">
                Snack history
            </div>
            <div onClick={() => onViewAll()} className="bg-zinc-700 p-[5px] px-[10px] rounded-xl text-[12px]">View all</div>
        </div>
        <div className="">
            <MultipleSlider items={full_history} />

        </div>
    </div>);
};


export const MySnacksList = ({ user = {} }) => {

    const games_history = user?.games_history || [];
    const reels_history = user?.reels_history || [];
    const comics_history = user?.comics_history || [];

    let full_history = [...games_history, ...reels_history, ...comics_history] || [];

    full_history?.sort((a, b) => b.earned_points - a.earned_points);

    // console.log(full_history)

    return(<div className='h-[80vh] overflow-y-scroll' >
            {
                [...full_history]?.map(item => {
                    return(<div key={item.id} className='my-[10px] bg-white shadow rounded-xl p-[10px] text-slate-500'>
                        <div className="flex items-center justify-between">
                                <div className=" flex items-center">
                                    <div className=" mr-[15px]">
                                        <div className="w-[40px] h-[40px] bg-slate-300 rounded-xl overflow-hidden">
                                            <ImagePlaceholder src={`${BASE_URL}${item?.played_snack?.cover_image?.url}`} alt="" />
                                        </div>
                                    </div>
                                    <div className="text-[14px] truncate mx-auto max-w-[100px] ">
                                    {item?.played_snack?.title}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-[14px] truncate  max-w-[100px] flex items-center mr-[10px]">
                                        <img alt='coin' src={BlahCoin} className='w-[15px] h-[15px] mr-[5px]' />    
                                        <div className="text-slate-900 font-bold">
                                            {item?.earned_points}

                                        </div>
                                    </div>
                                 

                                </div>
                            </div>
                    </div>)
                })
            }
    </div>)
}
