import React from 'react';
// import { ChangePassword } from './ChangePassword';
import { Preference } from './Preference';
import { PowerPage } from './PowerPage';
import Logout from './Logout';

export const SettingsPage = () => {

    return (<div>
        <div className="text-[14px] py-[10px] ">
            {/* <ChangePassword /> */}
            <div className="">

            <PowerPage/>
            </div>
            <div className="pt-[40px]">

            <Preference />
            </div>
            <Logout/>

            {/* <hr className='my-[20px]' /> */}
            

        </div>

    </div>);
};


