function Badge(props) {

    const {snack_type = ""} = props

    // console.log(snack_type)
    return (  <div className="text-[12px] capitalize overflow-hidden min-w-[50px] text-center  py-[5px] bg-white rounded-[12px] w-fit text-black font-bold px-[10px]  ">
    {snack_type === "reel" ? "Shots" : snack_type}
</div>);
}

export default Badge;