export default function IconBookMark  (props) {

    const { isBookmarked = false, onClick=() => {}} = props
    return (<div 
        onClick={onClick} className="">
        <div className="mx-auto w-fit ">

        <svg xmlns="http://www.w3.org/2000/svg" fill={isBookmarked?"yellow" :"white"} width="24" height="24" viewBox="0 0 24 24"><path d="M19 24l-7-6-7 6v-24h14v24z"/></svg>
        </div>
     
    </div>);
};
 