import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


import useIsInViewport from "../../useIsInViewport";
import RighSideNav from "../Navs/RightSideNav";
import DisplayDetails from "./DisplayDetails";

import { useAuthContext } from "Auth/AuthContext";
import axios from "axios";
import { API, BASE_URL, BEARER } from "constant";
import { headers } from "helpers";

import {useIsPWA } from "useIsPwa"

const VideoCard = ({
  index,
  snack_id,

  //snack card
  // author,
  // authorLink,

  title,
  description,

  videoURL,
  cover_image,
  lastVideoIndex,
  getnewSnacks,

  //content
  snackType,
  game_url,

  publisherDetails = {},

  like_count,
  openPublisherPage,

  wrapperClass = "",
  // isLiked = false

}) => {

  const location = useNavigate()

  const { user } = useAuthContext()

  const isPWA = useIsPWA();


  //=============================================
  //Video
  //=============================================

  const video = useRef();
  const isInViewport = useIsInViewport(video);
  const [loadNewVidsAt, setloadNewVidsAt] = useState(lastVideoIndex);

  if (isInViewport) {
    setTimeout(() => {
      video.current.play();
    }, 1000);

    if (loadNewVidsAt === Number(video.current.id)) {
      setloadNewVidsAt((prev) => prev + 2);
      getnewSnacks(loadNewVidsAt + 2);
    }
  }

  const togglePlay = () => {
    let currentVideo = video.current;
    if (currentVideo.paused) {
      currentVideo.play();
    } else {
      currentVideo.pause();
    }
  };

  useEffect(() => {
    if (!isInViewport) {
      video.current.pause();
    }
  }, [isInViewport]);

  //====================================================
  //====================================================

  const snackButtonClick = () => {
    if (snackType == "game" && game_url) {
      location(`/?game_url=${game_url}&&snack_type=${snackType}&&snack_id=${snack_id}&&snack_name=${title}`)
    } else if (snackType == "comic") {
      location(`/?snack_type=${snackType}&&snack_id=${snack_id}&&snack_name=${title}`)
    } else if (snackType == "reel") {
      location(`/?snack_type=${snackType}&&snack_id=${snack_id}&&snack_name=${title}`)
    }
  }


  //====================================================
  //Like btn
  //====================================================

  const [islikedStatus, setisLikedStatus] = useState(false)

  const likeButtonClick = () => {
    // console.log("lol")
    updateLike(!islikedStatus)
    setisLikedStatus(!islikedStatus)

  }

  const updateLike = async (likeStatus) => {
    try {
      const res = await axios.post(`${API}/snackr/user/like`,
        {
          status: likeStatus,
          snackType: snackType,
          snack_id: snack_id
        }, {
        headers: headers()
      }
      )
      console.log(res)
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    // console.log(snack_id)

    const flag = user?.[`liked_${snackType}s`]?.find(item => item.id == snack_id)

    setisLikedStatus(flag ? true : false)

  }, [user])


  //====================================================
  //bookmark btn
  //====================================================

  const [isBookmarkedStatus, setIsBookmarkedStatus] = useState(false)

  const bookmarkButtonClick = () => {
    // console.log("lol")
    updateBookmark(!isBookmarkedStatus)
    setIsBookmarkedStatus(!isBookmarkedStatus)

  }

  const updateBookmark = async (bookmarkStatus) => {
    try {
      const res = await axios.post(`${API}/snackr/user/bookmark`,
        {
          status: bookmarkStatus,
          snackType: snackType,
          snack_id: snack_id
        }, {
        headers: headers()
      }
      )
      console.log(res)
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    // console.log(snack_id)

    const flag = user?.[`bookmarked_${snackType}s`]?.find(item => item.id == snack_id)
    setIsBookmarkedStatus(flag ? true : false)


  }, [user])
  //====================================================
  //====================================================

  const _bg_colors = [
    " from-orange-800 via-orange-100 to-orange-500",
    " from-pink-800 via-pink-100 to-pink-500",
    " from-green-800 via-green-100 to-green-500",
  ]

  return (
    <div className={` slider-children overflow-x-hidden ${ wrapperClass}`}>
      {/* ========================================= */}
      {/* If video url available and if its a reel  */}
      {/* ========================================= */}
      {
        videoURL && snackType == "reel" ?

          <div className={`h-screen w-full flex flex-col justify-center items-center bg-gradient-to-b ${_bg_colors[Math.floor(Math.random() * 3)]} `}>
            <div className="p-[10%]">
              <div className="border-[10px] rounded-xl border-white shadow-lg ">


                <video
                  muted

                  disablePictureInPicture
                  controlsList="nodownload"

                  // className={` video ${_bg_colors[Math.floor(Math.random() * 3)]}`}
                  ref={video}
                  // onClick={togglePlay}
                  id={index}
                  // autoPlay={index === 1}
                  autoPlay={true}
                  playsInline={true}
                  loop={true}


                >
                  loading...
                  <source src={videoURL} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          :
          <>
            {/* ======================================== */}
            {/* If its a game or comic */}
            {/* ======================================== */}
            <video
              muted

              disablePictureInPicture
              controlsList="nodownload"

              className={` video ${_bg_colors[Math.floor(Math.random() * 3)]}`}
              ref={video}
              // onClick={togglePlay}
              id={index}
              // autoPlay={index === 1}
              autoPlay={true}
              playsInline={true}
              loop={true}


            >
              <source src={videoURL} type="video/mp4" />
            </video>
          </>
      }


      {/* =========================================== */}
      {/* If video URL is not updated in dashboard  or if its a comic*/}
      {/* =========================================== */}

      {!videoURL &&
        <div className={`h-screen w-full flex flex-col justify-center items-center bg-gradient-to-b ${_bg_colors[Math.floor(Math.random() * 3)]} `}>
          {snackType == "comic" ?
            <div className="p-[20%] ">
              <div className="relative">

                <img alt="" src={cover_image} className="border-[10px] rounded-xl border-white shadow-lg rotate-[20deg]" />
                <img alt="" src={cover_image} className="border-[10px] rounded-xl border-white shadow-lg absolute left-0 top-0 " />

              </div>
            </div>
            :
            <div>

              <img alt="" src={cover_image} />
            </div>
          }
        </div>
      }


      <div className="absolute w-full h-full bg-gradient-to-b from-transparent via-transparent to-black/80 left-0 top-0 "></div>
      <div className={` absolute bottom-0 ${isPWA ? " pb-[120px] " : "pb-[200px] sm:pb-[120px]" } w-full _backdrop-blur-sm _bg-black/40 `} onClick={() => { }}>

        <DisplayDetails
          id={snack_id}
          gamename={title}
          snackType={snackType}
          description={description || ""}
          snackButtonClick={snackButtonClick}
        />
      </div>
      <RighSideNav
        //like
        is_liked={islikedStatus}
        like_count={like_count}
        onClick_publisherBtn={() => openPublisherPage()}
        onClick_likeBtn={() => likeButtonClick()}
        publisherDetails={publisherDetails}

        //bookmark
        is_bookmarked={isBookmarkedStatus}
        onClick_bookMrkBtn={() => bookmarkButtonClick()}

        //sharebutton
        id={snack_id}
        gamename={title}
        snackType={snackType}
        gameUrl={game_url}

        isPWA={isPWA}


      />

    </div>
  );
};

export default VideoCard;