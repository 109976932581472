// import { Children } from "react";
import {motion} from "framer-motion"
import { WalletButton } from "../Components/Navs/WalletButton";


function SnackContainer({ children, ...props }) {

    const { closeCallback } = props

    return (< motion.div
        initial={{ opacity: 1, translateY: "100vh" }}
        animate={{ opacity: 1, translateY: "0vh" }}
        exit={{ opacity: 1, translateY: "100vh" }}
        // transition={{ type: "Tween", stiffness: 50 }}
        className="fixed fixedmd:max-w-[400px] md:left-auto left-0 z-[7000]  top-0 w-full h-screen ">
        <div className="_container _mx-auto md:max-w-[400px]  ">


            <div className="flex justify-between items-center py-[5px] px-[12px] bg-violet-400">
                <WalletButton holdClick={true} />

                <div onClick={() => closeCallback()} className="w-fit bg-white px-[12px] py-[3px] shadow rounded-lg text-slate-900 font-bold">
                    Close
                    {/* <svg className="h-[35px] w-[35px]" fill="white" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-8.991 6.932 2.717-2.718c.146-.146.338-.219.53-.219.405 0 .751.325.751.75 0 .193-.073.384-.219.531l-2.718 2.717 2.728 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.531-.219l-2.728-2.728-2.728 2.728c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .384.073.53.219z" fill-rule="nonzero" /></svg> */}
                </div>
            </div>

        </div>
        {children}
    </motion.div>);
}

export default SnackContainer;