import React, { useContext, useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';


import { Store as wallatStore } from 'StoreContext/wallatStore'
// import Container from '../../Component/Container';

// import ReactGA from "react-ga4";
// const TRACKING_ID = "G-98TS1L459T";
// ReactGA.initialize(TRACKING_ID)

import { useAuthContext } from "Auth/AuthContext";
import { API } from 'constant';
import { headers } from 'helpers';
import axios from 'axios';
import PopOverlay from 'Components/PopupOverlay';
import { MySnacksList, YourSnacks } from './YourSnacks';
import { TopSnack, TopSnackList } from './TopSnack';

import { SettingsPage } from './SettingsPage';
import { ProfileSettings } from './ProfileSettings';
import { motion } from 'framer-motion';
import { TransactionList } from './TransactionList';
import { ButtonPrimary } from 'Components/ButtonPrimary';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Login } from 'Pages/Auth/login';
import { SignUp } from 'Pages/Auth/SignUp';

import Bg from "Assets/profileBG.JPG"
import { ForgetPassword } from 'Pages/Auth/ForgerPassword';
import AvatarEditor from './Avata_editer';
import { MyBookmarks } from './Bookmarks';
import { ImagePlaceholder } from 'Components/ImagePlaceholder';
import ClaimBonus from './ClaimBonus';
import { TruncateText } from 'utils';

//=============================================
//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================

function Profile() {

    useEffect(() => {
        sendAnalytics(`screen_view`, {
            firebase_screen: "Profile page",
            firebase_screen_class: "Profile page"
        })
    }, [])

    const { user } = useAuthContext();
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation();

    // console.log(user)

    let _store_wallet = useContext(wallatStore)

    const fetchUserData = async () => {
        try {

            const res = await axios.get(`${API}/snackr/user/data`, {
                headers: headers()
            })
            const userData = res?.data?.user || {}

            //   console.log(res.data)

            _store_wallet.dispatch({
                type: 'initUser',
                payload: { user: userData }
            })
            // setvideos((oldVideos) => [...oldVideos, ...snacks]);


        } catch (ex) {

        }
    }

    const fetchTopSnackRData = async () => {
        try {

            const res = await axios.get(`${API}/snackr/user/topSnackrofday`, {
                headers: headers()
            })
            const topSnackr = res?.data?.topSnackrs || {}

            //   console.log(res.data)

            _store_wallet.dispatch({
                type: 'updateTopSnackr',
                payload: { topSnackr: topSnackr }
            })
            // setvideos((oldVideos) => [...oldVideos, ...snacks]);


        } catch (ex) {

        }
    }

    useEffect(() => {
        fetchUserData()
        fetchTopSnackRData()
    }, [])

    const [popupKey, setPopupKey] = useState("")

    const openPopup = (key = "") => {
        // searchParams.append("popup", key)
        // console.log(searchParams.get("popup"))
        setPopupKey(key)
        navigate(`${location.pathname}?popup=${key}`)
        if(key){
            sendAnalytics(`screen_view`, {
                firebase_screen: `Popup_opened_${key}`,
                // firebase_screen_class: "Profile page"
            })
        }

    }


    const closePopup = () => {
        searchParams.delete("popup")
        setPopupKey("")
        navigate(location.pathname, {
            search: searchParams.toString()

        })
    }

    const closePopupWithUserRefetch = () => {
        fetchUserData()
        fetchTopSnackRData()
        closePopup()
    }



    useEffect(() => {
        if (searchParams.get("popup")) {
            openPopup(searchParams.get("popup"))
        }
    }, [searchParams])


    const [fakeDataList, setFakeDataList] = useState([]);

    useEffect(() => {
        const generateFakeData = (count) => {
            const data = [];
            for (let i = 0; i < count; i++) {
                data.push({
                    avatar_url: `/extra/images/profile/Vegies/Asset ${Math.floor(Math.random() * 6) + 4}.png`,
                    username: faker.internet.userName(),
                    blahcoin_today_earning: faker.datatype.number({ min: 1, max: 100 }).toString(),
                });
            }
            return data;
        };

        setFakeDataList(generateFakeData(2)); // Generate 10 sets of fake data
    }, []);



    return (
        <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[90] h-full' >
            {/* {console.log(_store_wallet.state?.user)} */}
            <motion.div
                initial={{ opacity: 0, _translateY: "100vh" }}
                animate={{ opacity: 1, _translateY: "0vh" }}
                exit={{ opacity: 0, _translateY: "100vh" }}

                // initial={{ opacity: 1, translateY: "100vh" }}
                // animate={{ opacity: 1, translateY: "0vh" }}
                // exit={{ opacity: 1, translateY: "100vh" }}

                transition={{ type: "Tween", stiffness: 50 }}

                className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200]  h-full w-full '>
                {/* <Container> */}
                {/* {console.log(_store_wallet.state)} */}
                <div
                    style={{ background: `url(${Bg}) white `, backgroundSize: "cover" }}
                    className=" min-h-full bg-white ">

                    <CoverImageSection />

                    <div className="px-[12px] translate-y-[-50px] ">
                        <div className="  w-full   text-slate-900  rounded-lg py-[20px] text-white">
                            {/* profile card */}
                            <ProfileDetails user={_store_wallet?.state?.user || user} onAvatarEdit={() => openPopup("avatar")} />
                            {/* Options card */}
                        </div>

                        {user ? <>

                            <div className="">

                                <ProfileOptions
                                    onProfileEdit={() => openPopup("profile")}
                                    onBookmarkEdit={() => openPopup("bookmark")}
                                    onSettingsEdit={() => openPopup("settings")}
                                />

                            </div>


                            <div className="">
                                {
                                    _store_wallet.state.topSnackr?.length > 0 &&
                                    <TopSnack snackrs={[..._store_wallet.state.topSnackr, ...fakeDataList]} onViewAll={() => openPopup("topsnackers")} />
                                }
                            </div>


                            {/* <div className="">

                                <YourSnacks user={_store_wallet.state.user} onViewAll={() => openYourSnackPage()} />

                            </div> */}

                        </> : <>
                            <div className="px-[0px]">

                                <div className=' w-full bg-violet-200  bg-gradient-to-br from-violet-100 to-violet-300  text-slate-500 text-black  rounded-xl p-[20px] text-center  '>
                                    <div className="text-[24px] font-bold text-slate-900">

                                    Login
                                    </div>
                                    <div className="text-[14px] pt-[10px]">

                                        Login for a more rewarding experience!
                                    </div>
                                    <div className="pt-[10px]">

                                        <div className="w- mx-auto mt-[10px] text-white">
                                            {/* <Link to="/login"> */}
                                            <ButtonPrimary
                                                className="w-full text-center  py-[10px] text-[14px]"
                                                onClick={() => openPopup("login")} > LOGIN </ButtonPrimary>
                                            {/* </Link> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>}



                    </div>
                    <div className="py-[100px]"></div>
                </div>
                {/* </Container> */}

            </motion.div>
            <PopOverlay isVisible={popupKey === "history"} onClose={() => closePopup()} wrapperClass="h-full w-full bg-gradient-to-br from-violet-100 to-violet-300" >
                <MySnacksList user={_store_wallet.state.user} />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "avatar"} onClose={() => closePopup()} >
                <AvatarEditor callback={closePopupWithUserRefetch} activeAvatar={_store_wallet?.state?.user?.avatar_url} />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "profile"} onClose={() => closePopup()} wrapperClass="h-full w-full bg-gradient-to-br from-violet-100 to-violet-300" >
                <ProfileSettings />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "bookmark"} onClose={() => closePopup()} wrapperClass="h-full w-full bg-gradient-to-br from-violet-100 to-violet-300" >
                <MyBookmarks user={_store_wallet.state.user} />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "settings"} onClose={() => closePopup()} >
                <SettingsPage />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "topsnackers"} onClose={() => closePopup()} wrapperClass="h-full w-full bg-gradient-to-br from-violet-100 to-violet-300"  >
                <TopSnackList snackrs={[..._store_wallet.state.topSnackr, ...fakeDataList]}  />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "yourtransactions"} onClose={() => closePopup()} >
                <TransactionList items={_store_wallet.state?.user?.transaction_histories} />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "login"} onClose={() => closePopup()} wrapperClass="h-full w-full bg-gradient-to-br from-violet-100 to-violet-200" >
                <Login callback={closePopupWithUserRefetch} signupRedirect={() => openPopup("signin")} forgetPassRedirect={() => openPopup("forgetpassword")} />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "signin"} onClose={() => closePopup()} wrapperClass="h-full w-full bg-gradient-to-br from-violet-100 to-violet-200" >
                <SignUp callback={() => openPopup("claimBonus")} loginRedirect={() => openPopup("login")} />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "claimBonus"} onClose={() => closePopup()} wrapperClass="h-full w-full _bg-gradient-to-br from-violet-100 to-violet-200" >
                <ClaimBonus callback={closePopup} />
            </PopOverlay>
            <PopOverlay isVisible={popupKey === "forgetpassword"} onClose={() => closePopup()} wrapperClass="h-full w-full bg-gradient-to-br from-violet-100 to-violet-200" >
                <ForgetPassword callback={closePopup} loginRedirect={() => openPopup("login")} />
            </PopOverlay>
        </div>
    );
}

export default Profile;


export const ProfileDetails = ({ user, onAvatarEdit = () => { } }) => {

    const onClickAvatar = () => {
        // console.log(user)
        if (user?.username) {
            onAvatarEdit()
        }
    }

    return (
        <div className="shadow bg-violet-100 p-[20px] rounded-xl text-slate-900">
            <div className="flex items-center ">
                <div className="">

                    <div onClick={() => onClickAvatar()} className="h-[70px] w-[70px] bg-white rounded-full overflow-hidden ">
                        {/* {console.log(user?.avatar_url)} */}
                        <ImagePlaceholder src={user?.avatar_url || "/extra/images/profile/human/Asset 4.png"} alt="" />
                    </div>
                </div>
                <div className=" pl-[20px]">
                    <div className="text-[14px] font-bold"> {user?.username || "Guest user"}  </div>
                    {/* <div className="text-[12px] font-bold"> {user?.profile_slug}  </div> */}
                </div>

            </div>
            <div className="">

                {

                    user?.publisher_description && <div className="text-[12px] mt-[10px]"> {TruncateText(user?.publisher_description, 400)}  </div>
                }
            </div>
        </div>
    )
}


const ProfileOptions = ({ onProfileEdit, onBookmarkEdit, onSettingsEdit }) => {
    return (<div className=" flex justify-between px-[12px] text-zinc-900 bg-gradient-to-br from-violet-100 to-violet-200 rounded-lg shadow">
        <div className="flex justify-center border  border-l-0 w-1/3">

            <div onClick={() => onProfileEdit()} className="flex flex-col justify-center items-center">
                <div className="text-[14px] font-bold pb-[10px]">  Profile </div>
                <div className=" bg-white shadow text-zinc-900 _border rounded-[10px] p-[10px] h-[50px] w-[50px] border-white  flex-col flex items-center justify-center">
                    <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.25 6c.398 0 .75.352.75.75 0 .414-.336.75-.75.75-1.505 0-7.75 0-7.75 0v12h17v-8.75c0-.414.336-.75.75-.75s.75.336.75.75v9.25c0 .621-.522 1-1 1h-18c-.48 0-1-.379-1-1v-13c0-.481.38-1 1-1zm-2.011 6.526c-1.045 3.003-1.238 3.45-1.238 3.84 0 .441.385.626.627.626.272 0 1.108-.301 3.829-1.249zm.888-.889 3.22 3.22 8.408-8.4c.163-.163.245-.377.245-.592 0-.213-.082-.427-.245-.591-.58-.578-1.458-1.457-2.039-2.036-.163-.163-.377-.245-.591-.245-.213 0-.428.082-.592.245z" fill-rule="nonzero" /></svg>
                </div>
            </div>

        </div>
        <div onClick={() => onBookmarkEdit()} className="flex justify-center border p-[20px] border-x-0  w-1/3">
            <div className="flex flex-col justify-center items-center text-center ">
                <div className="text-[14px] font-bold pb-[10px] "> Bookmarks </div>

                <div className="bg-white shadow text-zinc-900 _border rounded-[10px] p-[10px] h-[50px] w-[50px] border-white flex-col flex items-center justify-center">
                    <svg clip-rule="evenodd" fill-rule="evenodd" width="25" height="25" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m2.394 15.759s7.554 4.246 9.09 5.109c.165.093.333.132.492.132.178 0 .344-.049.484-.127 1.546-.863 9.155-5.113 9.155-5.113.246-.138.385-.393.385-.656 0-.566-.614-.934-1.116-.654 0 0-7.052 3.958-8.539 4.77-.211.115-.444.161-.722.006-1.649-.928-8.494-4.775-8.494-4.775-.502-.282-1.117.085-1.117.653 0 .262.137.517.382.655zm0-3.113s7.554 4.246 9.09 5.109c.165.093.333.132.492.132.178 0 .344-.049.484-.127 1.546-.863 9.155-5.113 9.155-5.113.246-.138.385-.393.385-.656 0-.566-.614-.934-1.116-.654 0 0-7.052 3.958-8.539 4.77-.211.115-.444.161-.722.006-1.649-.928-8.494-4.775-8.494-4.775-.502-.282-1.117.085-1.117.653 0 .262.137.517.382.655zm10.271-9.455c-.246-.128-.471-.191-.692-.191-.223 0-.443.065-.675.191l-8.884 5.005c-.276.183-.414.444-.414.698 0 .256.139.505.414.664l8.884 5.006c.221.133.447.203.678.203.223 0 .452-.065.689-.203l8.884-5.006c.295-.166.451-.421.451-.68 0-.25-.145-.503-.451-.682z" fill-rule="nonzero" /></svg>
                </div>
            </div>
        </div>
        <div className="flex justify-center border p-[20px] border-r-0  w-1/3">
            <div onClick={() => onSettingsEdit()} className="flex flex-col justify-center items-center">
                <div className="text-[14px] font-bold pb-[10px]">  Settings </div>
                <div className="bg-white shadow text-zinc-900 _border rounded-[10px] p-[10px] h-[50px] w-[50px] border-white flex-col flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M6 18h-2v5h-2v-5h-2v-3h6v3zm-2-17h-2v12h2v-12zm11 7h-6v3h2v12h2v-12h2v-3zm-2-7h-2v5h2v-5zm11 14h-6v3h2v5h2v-5h2v-3zm-2-14h-2v12h2v-12z" /></svg>
                </div>
            </div>
        </div>
    </div>)
}

const CoverImageSection = () => {

    return (<div
        style={{ background: `url(${Bg})`, backgroundSize: "cover" }}
        className="bg-violet-400 w-full h-[100px]  relative bg-center ">
        <div className="mx-auto w-full px-[12px] absolute bottom-0">

        </div>

    </div>)
}


const GamePlayedCard = ({ item = {} }) => {

    return (
        <div className=' bg-zinc-800 rounded-xl shadow text-white mb-[10px]'>
            <div className="flex items-center p-[10px]">

                <div className="">
                    <div className="h-[30px] w-[30px] rounded-full bg-white"></div>
                </div>
                <div className="p-[10px] -[10px] flex justify-between w-full items-center">
                    <div className="text-[12px] font-bold">   {item?.gamename} (#102) : </div>
                    {item?.highScore}
                </div>

            </div>
        </div>
    )
}

const ConsumerCard = ({ item = {} }) => {

    return (
        <div className=' bg-zinc-800 rounded-xl shadow text-white mb-[10px]'>
            <div className="flex items-center p-[10px]">

                <div className="">
                    <div className="h-[30px] w-[30px] rounded-full bg-white"></div>
                </div>
                <div className="p-[10px] -[10px] flex justify-between w-full items-center">
                    <div className="flex items-center">
                        <div className="text-[14px] font-bold mr-[2px]"> {item.rank} </div>
                        <div className="text-[12px] font-bold text-slate-400">   {item?.username}  </div>
                    </div>
                    {item?.snack_score}
                </div>

            </div>
        </div>
    )
}

const fake_consumers = [
    {
        id: 1,
        username: "KingBling98",
        snack_score: "2100",
        rank: "#1"
    },
    {
        id: 2,
        username: "dashamoolam19",
        snack_score: "1820",
        rank: "#2"
    },
    {
        id: 3,
        username: "bikerJoe_lol",
        snack_score: "1010",
        rank: "#3"
    },
    {
        id: 4,
        username: "You",
        snack_score: "508",
        rank: "#201",
    },
]