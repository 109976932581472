import { useNavigate } from "react-router-dom";


function IconProfile(props) {
    const { image, id = 1 , onClick} = props

    const navigate = useNavigate()

    // console.log(image)

    return (<div
        onClick={() => onClick()}
        className="relative w-fit">
        <div className="relative w-[30px] h-[30px] rounded-full overflow-hidden bg-white mr-[10px] border-4 relative">

            <img src={image} alt="" className="w-full" />
            {/* <svg className="" width="30" height="30" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" /></svg> */}
        </div>
        <div className="absolute right-[5px] top-[15px] h-[20px] w-[20px] ">
            <div>
                <svg fill="red" className="bg-white rounded-full max-w-[20px]" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm-.747 9.25h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fillRule="nonzero" /></svg>
            </div>
        </div>
    </div>);
}

export default IconProfile;