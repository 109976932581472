import { useLocation, useParams, useSearchParams, matchPath } from 'react-router-dom';

import DiscoverPage from "./Discover"
import FavouritePage from "./Favourite"
import StorePage from "./Store"
import ProfilePage from "./Profile"
import { AnimatePresence } from 'framer-motion';
import DeleteUser from './DeleteUser';

function PageLoader() {

    const { pathname } = useLocation();

    const isDiscover = matchPath("/discover", pathname);
    const isFavourite = matchPath("/favourite/*", pathname);
    const isProfile = matchPath("/profile/*", pathname);
    const isStore = matchPath("/store/*", pathname);
    const isCreator = matchPath("/creator/*", pathname);
    const isDeleteUser = matchPath("/profile/deleteuser", pathname);

    return (<div className='relative max-w-[400px] mx-auto '>
        {/* <AnimatePresence > */}

        {
            isDiscover && <DiscoverPage  key={"discovery_page"}/>
        }
        {
            isFavourite && <FavouritePage key={"Favourite_page"} />
        }
        {
            isStore && <StorePage key={"Store_page"} />
        }
        {
            isProfile && <ProfilePage key={"Profile_page"} />
        }
        {
            isDeleteUser && <DeleteUser key={"delete_page"} />
        }
        {/* </AnimatePresence> */}
    </div>);
}

export default PageLoader;