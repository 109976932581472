import { motion, AnimatePresence } from "framer-motion"
import { ButtonPrimary } from "./ButtonPrimary";

function PopOverlay({ isVisible, onClose, wrapperClass, children, ...props }) {

    return (<div className="relative">
        <AnimatePresence >
            {isVisible && (
                <motion.div
                    key="modal"

                    className="w-full h-full  fixed top-0 fixed md:max-w-[400px] md:left-auto left-0 z-[6000] backdrop-blur-sm "
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    exit={{ opacity: 0, }}
                    transition={{ type: "Tween", stiffness: 50 }}
                >
                    <motion.div
                        initial={{ opacity: 1, translateY: "100vh" }}
                        animate={{ opacity: 1, translateY: "0vh" }}
                        exit={{ opacity: 1, translateY: "100vh" }}
                        transition={{ type: "Tween", stiffness: 50 }}
                        className="bg-white h-full mt-[50px]  shadow rounded-t-xl  ">

                        <div className=" py-[20px]  px-[10px] bg-gradient-to-tr from-violet-300 to-violet-500 rounded-t-xl">
                            <ButtonPrimary 
                                className="p-[5px] px-[10px] bg-white rounded-xl w-fit text-[14px] text-black font-bold"
                                onClick={() => onClose()} 
                                >
                                    Close
                            </ButtonPrimary>
                        </div>

                        <div className={` px-[20px] py-[20px] ${wrapperClass}`}>
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            )}

        </AnimatePresence>

    </div>);
}

export default PopOverlay;