import { removeToken } from 'helpers';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const PowerPage = () => {

    const navigate =  useNavigate()

    

    return (
        <div>
            <div className="text-[12px] text-zinc-600  ">

                <div className="flex items-center  ">
                    {/* 
                    <div className="mr-[10px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M14 12h-4v-12h4v12zm4.213-10.246l-1.213 1.599c2.984 1.732 5 4.955 5 8.647 0 5.514-4.486 10-10 10s-10-4.486-10-10c0-3.692 2.016-6.915 5-8.647l-1.213-1.599c-3.465 2.103-5.787 5.897-5.787 10.246 0 6.627 5.373 12 12 12s12-5.373 12-12c0-4.349-2.322-8.143-5.787-10.246z" /></svg>
                    </div> */}
                    <div className="font-bold text-[16px]">

                        Account
                    </div>
                </div>
                <div className=" text-[14px]  ">
                    <div className=" mt-[20px] ">
                        <div className="flex justify-between  ">
                            <div className="flex items-center">
                                <div className="flex items-center">
                                    <div className="mr-[10px] w-[25px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='grey' width="18" height="18" viewBox="0 0 24 24"><path d="M19 7.001c0 3.865-3.134 7-7 7s-7-3.135-7-7c0-3.867 3.134-7.001 7-7.001s7 3.134 7 7.001zm-1.598 7.18c-1.506 1.137-3.374 1.82-5.402 1.82-2.03 0-3.899-.685-5.407-1.822-4.072 1.793-6.593 7.376-6.593 9.821h24c0-2.423-2.6-8.006-6.598-9.819z"/></svg>
                                    </div>
                                    <div className="font-bold ">
                                    <Link to="/profile/deleteuser">
                                        Delete Account
                                    </Link>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" fill='grey' width="16" height="16" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                            </div>
                        </div>

                        <div className="h-[1px] w-full bg-slate-100 my-[10px]"></div>

                        <div className="flex justify-between  opacity-[0.3]">
                            <div className="flex items-center">
                                <div className="flex items-center">
                                    <div className="mr-[10px] w-[25px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill='grey' width="20" height="20" viewBox="0 0 24 24"><path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm4 11.723v2.277h-2v-2.277c-.596-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723z"/></svg>
                                    </div>
                                    <div className="font-bold">

                                        Privacy
                                    </div>
                                </div>
                               
                            </div>
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" fill='grey' width="16" height="16" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-slate-100 my-[10px]"></div>

                        <div className="flex justify-between  opacity-[0.3] ">
                            <div className="flex items-center">
                                <div className="flex items-center">
                                    <div className="mr-[10px] w-[25px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill='grey' width="20" height="20" viewBox="0 0 24 24"><path d="M12 1l-12 22h24l-12-22zm-1 8h2v7h-2v-7zm1 11.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
                                    </div>
                                    <div className="font-bold">

                                        Report an issue
                                    </div>
                                </div>
                               
                            </div>
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" fill='grey' width="16" height="16" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
                            </div>
                        </div>

                    </div>
                    {/* <div className=" bg-zinc-600 rounded-xl p-[10px] text-center mt-[20px]">
                        DELETE ACCOUNT
                    </div> */}
                    
                </div>
            </div>
        </div>
    );
};
