import axios from 'axios';
import { motion, AnimatePresence } from "framer-motion"


import React, { useEffect, useState, useContext } from 'react';
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";



// import { createClient } from "pexels";

import VideoCard from "Components/VideoCard";

import { Store as snackStore } from 'StoreContext/snackStore'
import { Store as walletStore } from 'StoreContext/wallatStore'

import GamePlay from 'Snacking/Game';
import ComicPlay from 'Snacking/Comic';
import ReelPlay from 'Snacking/Reel';

import { API, BASE_URL } from 'constant';
import { headers, randomSort } from 'helpers';
import PopOverlay from 'Components/PopupOverlay';
import PublisherDetails from './PublisherDetails';

import BeatLoader from "react-spinners/BeatLoader";
import { useFirstTimeCheck } from './useFirstTimeCheck';
import { SwipeUpAnimation } from './SwipeUpAnimation';

import { sendAnalytics } from 'firebaseConfig';



function Home() {

  //Analytics
  useEffect(() => {
    sendAnalytics(`screen_view`, {
      firebase_screen: "Home page",
      firebase_screen_class: "Home page"
    })
  }, [])




  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const game_url = searchParams.get('game_url');
  // const reel_url = searchParams.get('reel_url');
  const snack_type = searchParams.get('snack_type');
  const snack_id = searchParams.get('snack_id');
  const snack_name = searchParams.get('snack_name');


  let _store_snack = useContext(snackStore)

  // console.log(_store_snack?.state)
  let videos = _store_snack.state.snacks || []


  // const [videos, setvideos] = useState([]);
  const [videosLoaded, setvideosLoaded] = useState(false);


  const randomQuery = () => {
    const queries = ["Funny", "Art", "Animals", "Coding", "Space"];
    return queries[Math.floor(Math.random() * queries.length)];
  };

  // const getVideos = (length) => {
  //   // Replace with your Pexels API Key
  //   const client = createClient("ssGqWHWRuj17kjZhoH9bpb4oR1ncZLYjc9hxk3GdvSPFl08waifGx9Lp");

  //   const query = randomQuery();
  //   client.videos
  //     .search({ query, per_page: length })
  //     .then((result) => {
  //       // setvideos((oldVideos) => [...oldVideos, ...result.videos]);
  //       setvideosLoaded(true);
  //     })
  //     .catch((e) => setvideosLoaded(false));
  // };

  // const url = `http://localhost:1337`

  //==================================================
  //FTUE
  //==================================================

  const { isFirstTime, setRegularUsage } = useFirstTimeCheck()

  const [isFTUEVisible, setIsFTUEVisible] = useState(true);

  const triggerFTUE = (setIsVisible, seconds) => {
    setTimeout(() => {
      setIsVisible(false);
    }, seconds * 1000);
  };



  const fetchNewSnacks = async (start = 1, limit = 3, SplitShuffle = 0) => {
    try {

      const res = await axios.get(`${API}/snackr/feeds`, { params: { start, limit } })
      let snacks = res?.data || []
      snacks = randomSort(snacks, SplitShuffle )

      // console.log(randomSort(snacks))


      _store_snack.dispatch({
        type: 'addMoreSnacks',
        payload: { snacks: snacks }
      })

      // if(isFirstTime()){
      // call FTUE
      // setIsFTUEVisible(true)
      triggerFTUE(setIsFTUEVisible, 3)
      // }
      setRegularUsage()




    } catch (ex) {

    }
  }

  //============================================
  //funcrtion to get coin
  //============================================
  let _store_wallet = useContext(walletStore)

  const getWallet = async () => {
    try {

      const res = await axios.get(`${API}/snackr/user/blahcoin`, { headers: headers() })
      // console.log(res)

      const BlahCoin = res?.data?.BlahCoin

      if (BlahCoin) {

        _store_wallet.dispatch({
          type: 'updateBlahCoin',
          payload: { BlahCoin }
        })

      }

    } catch (ex) {

    }

  }


  useEffect(() => {
    // getVideos(3);
    fetchNewSnacks(1, 10, 4)
    getWallet()

  }, []);


  const [isPublisherPage, setisPublisherPage] = useState(false);

  const openPublisherPage = (id) => {
    setisPublisherPage(id)
  }

  const closePublisherPage = () => {
    setisPublisherPage(false)
  }

  const isHome = matchPath("/", pathname);




  return (<div >
    {/* {console.log(isFirstTime())} */}
    <SwipeUpAnimation  show={isFTUEVisible  && (videos.length > 0) && isHome }/>

    <div className="slider-container ">
      {/* {console.log(videos)} */}
      {videos.length > 0 ? (
        <>
          {videos.map((video, id) => (
            <VideoCard
              key={id}
              index={id}

              snack_id={video?.id}
              snackType={video?.type}

              title={video?.title}
              description={video?.description}

              game_url={video?.game_url || ""}
              cover_image={video?.cover_image?.url ? `${BASE_URL}${video?.cover_image?.url}` : ""}

              publisherDetails={video?.publisher || {}}

              videoURL={video?.video?.url ? `${BASE_URL}${video?.video?.url}` : ""}
              getnewSnacks={fetchNewSnacks}
              lastVideoIndex={videos.length - 1}

              like_count={video?.liked_users?.length || 0}
              openPublisherPage={() => openPublisherPage(video?.publisher?.id)}

            // wrapperClass={isFTUEVisible ? "animate-bounce" : ""}

            />
          ))}
        </>
      ) : (
        <>
          {/* <h1>Nothing to show here</h1> */}
          <div className="bg-slate-900 _bg-violet-500 w-full h-full flex flex-col justify-center items-center">
            <div className="w-fit mx-auto">
              <BeatLoader color='white' className='mx-auto' />
            </div>
          </div>
        </>
      )}
    </div>
    <AnimatePresence>

      {
        (game_url && snack_type == "game" && snack_id) &&
        <GamePlay
          key="game_snack"
          url={game_url}
          snack_id={snack_id}
          snack_name={snack_name}
          snack_type={snack_type}
        // updateWallatScore={updateWallatScore}
        // updateHighScore={updateHighScore}

        />
      }
      {
        (snack_type == "comic" && snack_id) &&
        <ComicPlay
          key="comic_snack"
          // url={game_url}
          snack_id={snack_id}
          snack_name={snack_name}
          snack_type={snack_type}
        // updateWallatScore={updateWallatScore}
        // updateHighScore={updateHighScore}

        />
      }
      {
        (snack_type == "reel" && snack_id) &&
        <ReelPlay
          key="reel_snack"

          // url={game_url}
          snack_id={snack_id}
          snack_name={snack_name}
          snack_type={snack_type}
        // updateWallatScore={updateWallatScore}
        // updateHighScore={updateHighScore}

        />
      }
    </AnimatePresence>
    <PopOverlay isVisible={isPublisherPage} onClose={() => closePublisherPage()} >
      {/* {console.log(isPublisherPage)} */}
      <PublisherDetails publisher={isPublisherPage} />
    </PopOverlay>
  </div>);
}

export default Home;

