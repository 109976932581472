import React, { Fragment, useState } from "react";


import { API, BASE_URL, BEARER } from "constant";

import { setToken, headers } from "helpers";
// import { headers } from "helpers";


import { ButtonPrimary } from '../../../Components/ButtonPrimary';
import axios from "axios";

export const ChangePassword = () => {


    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const [inputVals, setInputVals] = useState({
        password : "",
        newPassword : "",
        confirmPassword : "",

    })

    const handleInputValues = (e) => {
        setError("")
        setInputVals(prev => ({
            ...prev,
            [e.target.name] : e.target.value
        }))
    }

    const updatePasswordAPI = async() => {
        setIsLoading(true)
        try {
            const res = await axios.post(`${API}/snackr/user/changePassword`,
              {
                password : inputVals.password ,
                newPassword : inputVals.newPassword ,
                confirmPassword : inputVals.confirmPassword ,

              }, {
              headers: headers()
            }
            )

            // console.log(res)

            const newJWT = res?.data?.jwt || ""
            if(newJWT){
                setSuccess(true)
                setToken(`${newJWT}`)
            }

            


            
        } catch (ex) {

            setError(ex.response?.data?.error?.message || "Something went wrong")
            console.log(ex)
          }
        setIsLoading(false)

    }


    return (
        <div className=" text-[12px] text-black bg-white  _bg-gradient-to-tl to-violet-100 from-violet-200 p-[20px] rounded-[20px]">

            {/* <div className="flex items-center text-slate-900">

                <div className="mr-[10px]">
                    <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" /></svg>
                </div>
                <div className="font-bold">

                    Security
                </div>
            </div> */}
            {success ? 
                <div className="my-[20px] text-center text-green-500 p-[10px] bg-white shadow rounded-xl">
                    Password updated.
                </div> 
                : 
                <div className=" _text-slate-500 text-[14px]">

                    <div className="font-bold text-[16px]">
                        Change your password
                    </div>
                    <div className="mt-[20px]  ">
                        <input 
                            onChange={handleInputValues}
                            name="password"
                            type="password" className='bg-slate-200 w-full rounded-full  p-[10px]' placeholder='current password' />
                    </div>
                    <div className="mt-[20px]">
                        <input 
                            onChange={handleInputValues}
                            name="newPassword"
                            type="password" className='bg-slate-200 w-full rounded-full  p-[10px]' placeholder='New password' />
                    </div>
                    <div className="mt-[20px]">
                        <input 
                            onChange={handleInputValues}
                            name="confirmPassword"
                            type="password" className='bg-slate-200 w-full rounded-full  p-[10px]' placeholder='Confirm password' />
                    </div>
                    <div className="my-[10px] text-red-500 px-[5px]">
                        {error}
                    </div>
                    <div className="mt-[20px] text-white text-[14px]">
                        <ButtonPrimary 
                            disabled={isLoading}
                            className="w-full py-[10px] text-center"
                            onClick={() => updatePasswordAPI()}>
                            {isLoading ? "..." : "Update"}
                        </ButtonPrimary>
                    </div>
                </div>
            }
        </div>
        
    );
};
