import React from 'react'; 

export const SwipeUpAnimation = ({ show = false }) => {

  

  return (<>
    {show  ? <div className='absolute left-0 bg-black/80 top-0 w-full h-full  z-[50] flex items-center justify-center'>

      <div className=" animate-dragup">

        <div className="rotate-[-30deg] w-fit mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" className='scale-[2]' fill='white' width="24" height="24" viewBox="0 0 24 24"><path d="M4.021 10.688c1.208.172 2.51 1.312 2.979 1.781v-10.514c0-1.08.92-1.955 2-1.955s2 .875 2 1.955v6.058c0 .784.814.885.919.103.216-1.604 2.519-1.817 2.693.399.043.546.726.655.866.027.326-1.444 2.501-1.458 2.758.758.066.579.796.696.848.034.051-.67.281-.934.607-.934 1.098 0 2.309 2.019 2.309 4.41 0 4.295-3 4.306-3 11.19h-10c-.332-3.942-3.462-7.431-6.271-10.241-.488-.488-.729-1.052-.729-1.564 0-.93.759-1.688 2.021-1.507z" /></svg>
        </div>
        <div className="text-white mx-auto text-center mt-[20px] text-[25px]"> Swipe up </div>
      </div>

    </div> : null}

  </>
  );

};
