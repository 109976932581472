import React from 'react';

// import MultipleSlider from './MultipleSlider_Snacks';
// import BlahCoin from "Assets/blah_coin.PNG"

import { BASE_URL } from 'constant';
import IconBookMark from 'Components/Buttons/IconBookMark';
import SnackButtonHook from 'Hooks/SnackButtonHook';
import { ImagePlaceholder } from 'Components/ImagePlaceholder';



export const MyBookmarks = ({ user = {} }) => {

    const bookmarked_games = user?.bookmarked_games || [];
    const bookmarked_reels = user?.bookmarked_reels || [];
    const bookmarked_comics = user?.bookmarked_comics || [];

    let full_history = [...bookmarked_games, ...bookmarked_reels, ...bookmarked_comics] || [];

    // full_history?.sort((a, b) => b.earned_points - a.earned_points);

    const { onSnackClick } = SnackButtonHook()


    return(<div className='h-[80vh] overflow-y-scroll' >
        {
            full_history?.length <= 0 && <div>
                <div className="mt-[50px] text-center font-bold text-slate-500">
                    No bookmarks yet!
                </div>
            </div>
        }
            {
                [...full_history]?.map(item => {
                    return(<div 
                        onClick={() => onSnackClick(item)}
                        key={item.id} className='my-[10px] bg-white shadow rounded-xl p-[10px] text-slate-500'>
                        <div className="flex items-center justify-between">
                                <div className=" flex items-center">
                                    <div className=" mr-[15px]">
                                        <div className="w-[40px] h-[40px] bg-slate-300 rounded-xl overflow-hidden">
                                            <ImagePlaceholder src={`${BASE_URL}${item?.cover_image?.url}`} alt="" />
                                        </div>
                                    </div>
                                    <div className="text-[14px] truncate mx-auto max-w-[100px] font-bold ">
                                    {item?.title}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="text-[14px] truncate  max-w-[100px] flex items-center mr-[10px]">
                                      <IconBookMark isBookmarked={true}/>
                                    </div>
                                 

                                </div>
                            </div>
                    </div>)
                })
            }
    </div>)
}
