// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAGtW1AEn6btczKq9zvAgmXSzgP9AYq87I",
    authDomain: "snackr-analytics.firebaseapp.com",
    projectId: "snackr-analytics",
    storageBucket: "snackr-analytics.appspot.com",
    messagingSenderId: "770261096485",
    appId: "1:770261096485:web:8bab11d6c4746a1ef087cc",
    measurementId: "G-S7BN12EKP0"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const sendAnalytics = (eventName = "test", options = {}) => {
    logEvent(analytics, eventName, options )
}

export { app, analytics , logEvent, sendAnalytics};