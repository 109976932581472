// import SnackButtonImage from "../snackButtonImage.png"
import SnackButtonLogo from "./logo.png"

const ArrowIcon = () => {
    return (
        <svg fill="white" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m10.211 7.155c-.141-.108-.3-.157-.456-.157-.389 0-.755.306-.755.749v8.501c0 .445.367.75.755.75.157 0 .316-.05.457-.159 1.554-1.203 4.199-3.252 5.498-4.258.184-.142.29-.36.29-.592 0-.23-.107-.449-.291-.591-1.299-1.002-3.945-3.044-5.498-4.243z" /></svg>

    )
}

export const SnackButton = ({ onClick }) => {

    return (
        <div
            onClick={onClick}
            className="relative _z-[51] ml-[15px] mt-[15px] p-[10px] w-fit bg-[#a07af6] ring-[5px] ring-white _bg-zinc-900 rounded-full min-w-[280px] text-center font-bold  max-h-[50px]"
        >
            {/* <img src={SnackButtonImage} className="w-full max-w-[300px]" alt="" /> */}
            <div className="flex w-full items-center">
                <div className="flex w-full justify-center  items-center ">
                <div className=" ">

                    <div className="w-[30px]">
                        <img src={SnackButtonLogo} className="h-[30px]" alt="" />
                    </div>
                </div>

                    <div className="text-[16px] font-bold text-white ml-[10px]">
                        <p
                            style={{
                                filter: "drop-shadow(0px 0px 2px grey)"
                            }}
                            className=" ">

                            SNACK NOW
                        </p>
                    </div>
                    <div className="w-[25px] h-[25px] ">
                        <ArrowIcon />
                    </div>
                                </div>
            </div>
        </div>
    );
};
