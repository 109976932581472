import HomeButton from "../Buttons/HomeButton";
import DiscoverButton from "../Buttons/DiscoverButton";
import StoreButton from "../Buttons/StoreButton";
import ProfileButton from "../Buttons/ProfileButton";

const BottomNav = () => {
    return (
      <nav className="relative sm:max-w-[400px] md:left-auto mx-auto bottom-nav pt-[10px] px-[20px] text-[40px] _bg-white bg-zinc-200 z-[1000] shadow brightness-120 h-[60px]	">
       
        <HomeButton />
       
       <DiscoverButton /> 
       <StoreButton /> 
      
       <ProfileButton />
       
      </nav>
    );
  };
  
  export default BottomNav;