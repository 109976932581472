import { SnackButton } from "./SnackButton/SnackButton";

import {TruncateText} from '../../utils'
import Badge from "./Badge";
import { useState } from "react";

export default function DisplayDetails (props)  {

    const { snackButtonClick, 
            gamename = "", 
            snackType= "",
            description = "", hashtags, studioName, snack_type = "game" } = props;


    const [expand, setExpand] = useState(false)

    return (
    // <div className="absolute bottom-[160px] left-0 z-[22] w-[100%] ">
    <div className=" w-[100%]   ">
        <div className="_flex  items-left     ">
            <div className=" text-white w-full px-[12px] ">
                <Badge snack_type={snackType} className="" />
                <div className="text-[18px] font-bold pt-[5px] _bg-violet-100/20 w-fit _backdrop-blur-sm rounded-lg _mb-[5px]"> {gamename} </div>
                <div 
                    onClick={() => setExpand(!expand)}
                    className="text-[12px] overflow-hidden  w-[70%] pb-[3px] _bg-violet-100/20 _backdrop-blur-sm rounded-lg">
                    {
                        expand ? description : TruncateText(description, 100)
                    }
                
                </div>
            </div>
            <SnackButton onClick={snackButtonClick} />
        </div>
    </div>);
};
