import React from "react";

import { useNavigate, matchPath, useLocation } from "react-router-dom";
import {motion} from 'framer-motion'

import Home_active from "./UI_Images/bottomNav/home_active.png"
import Home_inactive from "./UI_Images/bottomNav/home_inactive.png"


function HomeButton() {

    const navigate = useNavigate()
    const { pathname } = useLocation();

    const isHome = matchPath("/", pathname);


    return (<div>
        <div
            onClick={() => navigate("/")}
            className="  relative">
            <div className="h-[40px] relative w-[60px] ">
                {isHome ? 
                
                <motion.div  
                    layoutId="nav_underline"    
                    className="absolute left-0 top-[-10px] w-full h-[47px] bg-violet-400 shadow rounded-b-xl  z-[100]"></motion.div> : null}

                    <img src={Home_inactive} className="h-[25px] relative z-[110] mx-auto" />
                {/* {isHome ?
                    <img src={Home_active} className="h-[25px]" />
                    :
                    <img src={Home_inactive} className="h-[25px]" />
                } */}
            </div>
        </div>
    </div>);
}

export default HomeButton;