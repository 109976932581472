import BlahCoin from "Assets/blah_coin.PNG"


function ClaimBonus() {
    return (  <div>
        <div className="mt-[20px]">
            <div className="mx-auto rounded-full overflow-hidden bg-violet-100 p-[10px] w-[150px] h-[150px] flex flex-col justify-center items-center">
                <div className="w-fit">
                <svg fill="#b49dfb" className="scale-[3]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"/></svg>
                </div>
            </div>
            <div className="font-bold text-[20px] text-center text-slate-500 mt-[20px]">
                Welcome onboard
            </div>
            <div className="text-center text-slate-500">
                You have earned +25 bonus points
            </div>
            <div className="w- mx-auto bg-violet-100 text-violet-500 px-[2x] py-[10px] rounded-full w-[100px] mt-[20px] text-center font-bold">
                <div className="flex justify-center items-center w-full">
                    <div className="mr-[5px]"> <img alt="" src={BlahCoin} className="h-[20px] w-[20px]" /></div>
                    <div className=""> +25</div>
                </div>
                
            </div>
        </div>


    </div>);
}

export default ClaimBonus;