import { useState, useContext, useEffect } from "react";

// import { useDetectClickOutside } from 'react-detect-click-outside';

import Fuse from "fuse.js";
import { Store as snackStore } from 'StoreContext/snackStore'
import { API, BASE_URL } from "constant";
import SnackButtonHook from "../../Hooks/SnackButtonHook";
import { ImagePlaceholder } from "../../Components/ImagePlaceholder";


function Search() {

    const { onSnackClick} = SnackButtonHook()

    let _store_snack = useContext(snackStore)

    const fuseOptions = {
        includeScore: true,
        includeMatches: true,
        threshold: 0.2,
        keys: ["title", "type"],
    }

    let allData = [..._store_snack.state?.allGames, ..._store_snack.state?.allComics, ..._store_snack.state?.allReels]

    // console.log(allData)

    const fuse = new Fuse(allData, fuseOptions);

    const [isfocused, setIsFocused] = useState(false)

    const [searchInput, setSearchInput] = useState("")
    
    const [searchResult, setSearchResult] = useState([])

    const handleInput = (e) => {
        const value = e.target.value
        setSearchInput(value)

        if (value.length === 0) {
            setSearchResult([]);
            return;
        }

        const results = fuse.search(value);
        const items = results.map((result) => result.item);
        setSearchResult(items)

        // console.log(results, value)
    }


    const ResultCard = (props) => {

        const { item } = props

        return (<div onClick={() => onSnackClick(item)} className=" hover:bg-zinc-700 p-[5px] _border-b-2 rounded-lg my-[5px] w-full">
            <div className="flex items-center">
                <div className="mr-[10px] w-[30px] h-[30px] overflow-hidden rounded-md">
                    <ImagePlaceholder src={`${BASE_URL}${item?.cover_image?.url}`} alt=""  className="w-full "/>
                </div>
                <div className="">
                    {item.title}
                </div>
            </div>
        </div>)

    }

    const closeDropdown = () => {
        setIsFocused(false)
    }

    const SearchResultContainer = ({closeDropdown}) => {

        // const ref = useDetectClickOutside({ onTriggered: closeDropdown });

        return(<div  className="w-full  relative">
        <div className="result absolute   z-[300] bg-zinc-400 rounded-lg w-full mt-[10px] p-[5px] px-[10px] py-[20px] shadow border-2">
            {
                searchResult?.length <= 0 &&
                    (<div className="text-center">
                        No match found!
                    </div>)
            }
            {
                searchResult.map(item => {
                    return (<ResultCard item={item} keys={item.id} />)
                })

            }
        </div>
        <div onClick={closeDropdown} className="absolute w-full h-screen bg-white/50 backdrop-blur-sm  z-[200]"></div>
    </div>)

    }


    return (<div>
        <div className="search p-[20px] relative">
            <div className="absolute left-[25px] top-[28px] z-[201]">
                <div className='w-[25px] h-[25px]'>
                    <svg  clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fill-rule="nonzero" /></svg>
                </div>
            </div>
            {/* {console.log(searchResult)} */}
            <input
                type="text"
                className='h-[40px] w-full border text-zinc-900 bg-zinc-300 rounded-lg px-[10px] pl-[35px]'
                placeholder='Search games'
                onChange={handleInput}
                value={searchInput}
                onClick={() => setIsFocused(true)}
                onFocus={() => setIsFocused(true)}

            />
            { searchInput.length > 0 && isfocused && <SearchResultContainer closeDropdown={closeDropdown} />}

            
        </div>
    </div>);
}

export default Search;