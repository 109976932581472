export const ButtonSecondary = ({ onClick = () => {}, children, className, ...props }) => {

    return (<div
        onClick={(e) => onClick(e)}
        className={` p-[5px] px-[10px] bg-slate-300 rounded-[20px] w-fit text-[12px] font-bold  ${className} `}
        {...props}
        >
        {children}
    </div>);


};
