import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BASE_URL } from 'constant';
import SnackButtonHook from 'Hooks/SnackButtonHook';

import BlahCoin from "Assets/blah_coin.PNG"
import { TruncateText } from 'utils';

function MultipleSlider(props) {

    const { items = [], heading = "" } = props

    const { onSnackClick } = SnackButtonHook()

    let _items = [...items]

    if(items.length <= 3){
        _items = [...items, ...items, ...items]
    }

    const sliderSettings = {
        dots: false,
        // centerMode: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 2, // Adjust the number of slides shown at once
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        // speed: 200,
        arrows: false, // Hide arrows
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    //   slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    //   slidesToShow: 3,
                },
            },
        ],
    };

    // console.log(items)


    return (<div>
        <div className="max-w-screen-xl mx-auto ">
            {
            heading &&
                <div className="font-bold text-[15px] pb-[20px]">
                    {heading}
                </div>
            }
            <Slider {...sliderSettings}>
                {/* Your individual slides go here */}
                {_items.map((item, index) => {
                    return (<div 
                        // onClick={() => onSnackClick(item?.played_game)}
                        className="px-[5px] " key={item?.id || index}>
                        <div className="h-[80px] w-full rounded-md overflow-hidden bg-white _bg-gradient-to-br from-violet-300 to-violet-400 p-[10px] shadow text-zinc-900">
                            <div className="flex items-center">
                                <div className="">
                                    <div className=" mr-[15px]">
                                        <div className="w-[50px] h-[50px] bg-slate-300 rounded-full overflow-hidden">
                                            <img src={item?.avatar_url} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="w-full text-[14px] truncate  max-w-[100px] pb-[5px]  ">
                                        {TruncateText(item?.username, 12)}
                                    </div>
                                    <div className="text-[12px] truncate mx-auto max-w-[100px] flex items-center ">
                                      <img alt='coin' src={BlahCoin} className='w-[15px] h-[15px] mr-[5px]' />   {item?.blahcoin_today_earning}
                                    </div>

                                </div>
                            </div>
                            
                            {/* <img
                                className="w-full h-fit object-cover   "
                                src={`${BASE_URL}${item?.cover_image?.url}`}
                                alt="Slide 1"
                            /> */}
                        </div>
                    </div>)
                })}


                {/* Add more slides as needed */}
            </Slider>
        </div>

    </div>);
}

export default MultipleSlider;