
export default  function IconHeart  (props)  {

    const {likes = 0, isLiked = false, onClick=() => {}} = props
    
    return (<div 
        onClick={onClick} className="">
        <div className="mx-auto w-fit ">

            <svg className="" width="35" height="35" fill={isLiked?"red" :"white"} clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 5.72c-2.624-4.517-10-3.198-10 2.461 0 3.725 4.345 7.727 9.303 12.54.194.189.446.283.697.283s.503-.094.697-.283c4.977-4.831 9.303-8.814 9.303-12.54 0-5.678-7.396-6.944-10-2.461z" fillRule="nonzero" /></svg>
        </div>
        <div className="text-white text-[8px] text-center w-fit mx-auto pr-[5px]">
            {likes}
        </div>
    </div>);
};
 