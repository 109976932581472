import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BASE_URL } from "constant";
import { ImagePlaceholder } from "../../Components/ImagePlaceholder";

const NextArrow = () => (<div> </div>)
const PrevArrow = () => (<div>  </div>)

function Carousal({images = []}) {

    const settings = {
        dots: false,
        infinite: true,
        speed: 200,
        autoplay: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <NextArrow /> , // Empty div to hide the previous arrow
        nextArrow: <PrevArrow />, // Empty div to hide the next arrow
        
      };




    //   let images = []


    return ( <div className="_p-[20px] ">
         <Slider {...settings}>
            {images && images.map((item, index) => (
              <div 
                key={index} 
                
                className=" px-[20px] rounded-xl overflow-hidden w-full _h-[250px] max-h-[350px] _bg-slate-200 _bg-gradient-to-r from-violet-300 via-violet-100 to-violet-300 _backdrop-blur-xl">
                {/* {console.log(game)} */}
                <ImagePlaceholder
                //   onClick={() => openGame(game)}
                  src={`${BASE_URL}${item?.cover_image?.url}`}
                  alt={`Image ${index + 1}`}
                  className="w-full h-fit rounded-xl mx-auto   "
                />
              </div>
            ))}
          </Slider>

    </div> );
}

export default Carousal;

