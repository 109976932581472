import { useState } from "react";


export const ImagePlaceholder = ({ src, alt, className }) => {

  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div className="relative w-full h-full">
      {/* Placeholder shimmer effect */}
      {!loaded && (
        <div className=" absolute top-0 left-0 w-full h-full bg-violet-300">
          <div className="animate-pulse w-full h-full  bg-gradient-to-r from-gray-200 via-gray-100 to-gray-300"></div>
        </div>
      )}

      {/* Actual image */}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        className={`${loaded ? 'block' : 'hidden'} object-cover w-full h-full ${className}`} />
    </div>
  );
};
