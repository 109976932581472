import React from 'react';
import video from 'Assets/splashscreen.mp4';


const SplashScreen = () => {
    return (
      <div className="fixed top-0 left-0 splash-screen w-full h-screen bg-[#752fd2] overflow-hidden">
        
        <video width="100%" height="auto" autoPlay={true} muted={true} loop={true} className=' h-screen mt-0 outline-0 '>
          <source src={video} width="100%" height="auto"  type="video/mp4" autoPlay  />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };
  
  export default SplashScreen