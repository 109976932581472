import React, { useState, useEffect } from 'react';

export const useFirstTimeCheck = () => {
//   const [isFirstTime, setIsFirstTime] = useState(false);

  const setRegularUsage = () => {
    localStorage.setItem('regularUser', true);
  }

  const isFirstTime = () => {

    const isRegularUser = localStorage.getItem('regularUser');

    if(isRegularUser){
        return false
    }

    return true

  }

//   useEffect(() => {
//     // Check if the app is opened for the first time
//     // const isRegularUser = localStorage.getItem('regularUser');

//   }, []);

  return {isFirstTime, setRegularUsage};
};
