import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "Auth/AuthContext";

import { API } from "constant";
import { setToken } from "helpers";
import { ButtonPrimary } from "Components/ButtonPrimary";


//=============================================
//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================


const SignupContainer = () => {

    return (<div>
        <div className="h-screen _bg-zinc-800 bg-[#a07af6]">
            <SignUp />
        </div>
    </div>)
}


export const SignUp = ({ callback = () => { }, loginRedirect }) => {

    useEffect(() => {
        sendAnalytics( `screen_view`, {
            firebase_screen: "Signup page", 
            firebase_screen_class: "Auth page"
        })
    }, [])

    const navigate = useNavigate();

    const { setUser } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");

    const [inputVals, setInputVals] = useState({
        email: "",
        name: "",
        password: "",
        confirmPassword: "",

    })

    const handleInputValues = (e) => {
        setError("")
        setInputVals(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }



    const onSignUp = async (values) => {

        // callback()
        //         window.location.reload();
        //         return
           
        if (values.password !== values.confirmPassword) {
            setError("Password confirm failed")
            return
        }
        setIsLoading(true);
        try {
            const value = {
                username: values.name,
                email: values.email,
                password: values.password,
                avatar_url : "/extra/images/profile/Vegies/Asset 4.png",
                blah_coin : 25
                // blah_coin : 0,
            };
            const response = await fetch(`${API}/auth/local/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            const data = await response.json();
            console.log(data)
            if (data?.error) {
                throw data?.error;
            } else {
                // set the token
                setToken(data.jwt);

                // set the user
                setUser(data.user);

                console.log(`Welcome back ${data.user.username}!`);

                // navigate("/", { replace: true });
                callback()
                window.location.reload();

            }
        } catch (ex) {
            // console.log(ex?.details?.errors?.[0]?.message);
            setError(ex?.details?.errors?.[0]?.message ?? ex?.message ?? ex.response?.data?.error?.message ??   "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };


    return (


        <div className="flex flex-col h-full w-full  justify-between items-center">


            <div className=" w-full p-[20px] text-slate-900">

                <div className="font-bold text-[24px]">
                    Sign Up
                </div>
                <div className="text-slate-500 text-[14px]">
                    Create your account.
                </div>

                <div className="w-full  py-[20px] rounded-xl text-slate-900 text-[14px]">

                    <div className="mt-[10px]">
                        <input
                            type="text" name="email"
                            className='bg-white w-full rounded-[20px]  p-[10px] '
                            placeholder='Email'
                            // disabled 
                            value={inputVals?.email}
                            onChange={handleInputValues}
                        />
                    </div>
                    <div className="mt-[10px]">
                        <input
                            type="text" name="name"
                            className='bg-white w-full rounded-[20px]  p-[10px] '
                            placeholder='Full name'
                            // disabled 
                            value={inputVals?.name}
                            onChange={handleInputValues}
                        />
                    </div>
                    <div className="mt-[10px]">
                        <input
                            type="text" name="password"
                            className='bg-white w-full rounded-[20px]  p-[10px] '
                            placeholder='Password'
                            value={inputVals?.password}

                            onChange={handleInputValues}

                        // disabled 
                        />
                    </div>
                    <div className="mt-[10px]">
                        <input
                            type="text" name="confirmPassword"
                            className='bg-white w-full rounded-[20px]  p-[10px] '
                            placeholder='Confirm password'
                            value={inputVals?.confirmPassword}

                            onChange={handleInputValues}

                        // disabled 
                        />
                    </div>
                    <div className="text-center my-[20px] text-red-500 text-[12px]">
                        {error}
                    </div>
                    <div className="_mt-[20px]">

                        <ButtonPrimary
                            disabled={isLoading}
                            onClick={() => onSignUp(inputVals)} className=" w-full py-[10px] text-center text-white font-bold text-[14px]">
                            {isLoading ? "..." : " SIGN UP"}
                        </ButtonPrimary>
                    </div>
                </div>
                <div className="my-[50px] text-center text-[14px] text-slate-900">
                    <div className="font-bold">
                        Already have an account? <span  onClick={() => loginRedirect()} className="text-violet-500"> Login</span>
                    </div>
                </div>
            </div>

        </div>
        // </div>

    );
}

export default SignupContainer;