import { removeToken } from 'helpers';
import { useNavigate } from 'react-router-dom';

function Logout() {

    const navigate = useNavigate()

  const LogoutCall = () => {
    removeToken()
    navigate("/")
    window.location.reload();

    }


    return (  <div onClick={() => LogoutCall()} className="hover:bg-red-900 bg-red-200 rounded-full p-[10px] text-center mt-[80px] text-red-500 font-bold text-[14px]">
    LOG OUT
</div>);
}

export default Logout;