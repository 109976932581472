import React from 'react';
import { ChangePassword } from './ChangePassword';
import { ChangeUserName } from './ChangeUserName';


export const ProfileSettings = () => {
    return (
        <div className="overflow-y-scroll h-[80vh]">
            <ChangePassword />
            <ChangeUserName />
            <div className="my-[200px]"></div>

        </div>
    );
};

